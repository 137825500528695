import * as React from "react";
import { Grid } from "@mui/material";
import {
  SimpleForm,
  Create,
  useTranslate,
  CheckboxGroupInput,
} from "react-admin";
import {
  TextInput,
  ReferenceInput,
  SelectInput,
  ReferenceArrayInput,
} from "../../components/Inputs";

export const FStationCreate = (props) => {
  const translate = useTranslate();
  return (
    <>
      <h2>{translate("resources.fstations.create")}</h2>
      <Create {...props} redirect={"list"}>
        <SimpleForm>
          <Grid container spacing={2}>
            <Grid item md={6}>
              <TextInput source="name" />
            </Grid>
            <Grid item md={6}>
              <ReferenceInput
                source="areaId"
                style={{ margin: 0 }}
                reference="areas"
              >
                <SelectInput
                  style={{ margin: 0 }}
                  translateChoice={false}
                  optionText="name"
                />
              </ReferenceInput>
            </Grid>
            <Grid item md={6}>
              <ReferenceArrayInput
                source="paymentTypes"
                reference="paymenttypes"
                allowEmpty
                filterToQuery={(s) => ({ q: s })}
              >
                <CheckboxGroupInput optionText="name" />
              </ReferenceArrayInput>
            </Grid>
            <Grid item md={12}>
              <TextInput multiline source="address" rows="4" />
            </Grid>
          </Grid>
        </SimpleForm>
      </Create>
    </>
  );
};
