import { Grid } from "@mui/material";
import * as React from "react";
import {
  SimpleForm,
  SimpleFormIterator,
  ReferenceInput,
  ArrayInput,
  Create,
  useTranslate,
} from "react-admin";
import { TextInput, SelectInput, NumberInput } from "../../components/Inputs";
import apiService from "../../services/apiService";

export const AreaCreate = (props) => {
  const initial = apiService.generateLocalizations("areas", {});
  console.log("Area Create", initial);
  const translate = useTranslate();
  return (
    <>
      <h2>{translate("resources.areas.create")}</h2>
      <Create {...props} redirect="list">
        <SimpleForm defaultValues={initial}>
          <Grid container spacing={2}>
            <Grid item md={6}>
              <TextInput source="name" />
            </Grid>
            <Grid item md={6}>
              <NumberInput source="priority" />
            </Grid>
            <Grid item md={6}>
              <SelectInput
                source="type"
                choices={[
                  {
                    id: "country",
                    name: translate("resources.areas.type.country"),
                  },
                  {
                    id: "region",
                    name: translate("resources.areas.type.region"),
                  },
                  {
                    id: "city",
                    name: translate("resources.areas.type.city"),
                  },
                  {
                    id: "district",
                    name: translate("resources.areas.type.district"),
                  },
                ]}
              />
            </Grid>
            <Grid item md={6}>
              <ReferenceInput source="upperId" reference="areas">
                <SelectInput translateChoice={false} optionText="name" />
              </ReferenceInput>
            </Grid>
            <Grid item md={12}>
              <ArrayInput source="locales">
                <SimpleFormIterator disableRemove disableAdd>
                  <TextInput source="localeKey" label="Locale" disabled />
                  <TextInput source="value" label="Value" />
                </SimpleFormIterator>
              </ArrayInput>
            </Grid>
          </Grid>
        </SimpleForm>
      </Create>
    </>
  );
};
