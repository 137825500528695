import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  DeleteButton,
  useTranslate,
} from "react-admin";
import { TextInput } from "../../components/Inputs";
import {  Typography } from "@mui/material";

const filters = [
  <TextInput source="q" label="Search" alwaysOn />,
  // <ReferenceInput source="mServiceId" label="Cameras" reference="mservices">
  //   <SelectInput optionText="name" />
  // </ReferenceInput>,
];

export const ProductsList = (props) => {
  const translate = useTranslate();
  return (
    <>
      <Typography variant="h2">{translate("resources.products.list")}</Typography>
      <List {...props} filters={filters}>
        <Datagrid size="medium">
          <TextField source="id" />
          <TextField source="name" />
          <TextField source="units" />
          <TextField source="vat" />
          <TextField source="productCode" />
          <TextField source="unitCode" />
          <EditButton />
          <DeleteButton />
        </Datagrid>
      </List>
    </>
  );
};
