import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  useTranslate,
  ReferenceField,
  DeleteButton,
} from "react-admin";
import { TextInput } from "../../components/Inputs";

const filters = [
  <TextInput source="q" label="Search" alwaysOn variant="outlined" />,
  //   <ReferenceInput source="parentId" label="SubAreas" reference="areas">
  //     <SelectInput optionText="name" variant="outlined" />
  //   </ReferenceInput>,
];

export const AutoList = (props) => {
  const translate = useTranslate();
  return (
    <>
      <h2>{translate("resources.autos.list")}</h2>
      <List {...props} filters={filters}>
        <Datagrid size="medium">
          <TextField source="id" />
          <ReferenceField source="clientId" reference="clients">
            <TextField source="name" />
          </ReferenceField>
          <TextField source="number" />
          <EditButton />
          <DeleteButton />
        </Datagrid>
      </List>
    </>
  );
};
