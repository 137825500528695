import * as React from "react";
import {
  Edit,
  SimpleForm,
  SimpleFormIterator,
  ArrayInput,
  useTranslate,
} from "react-admin";
import {
  TextInput,
  ReferenceInput,
  SelectInput,
  NumberInput,
} from "../../components/Inputs";

export const AreaEdit = (props) => {
  const translate = useTranslate();
  return (
    <>
      <h2>{translate("resources.areas.edit")}</h2>
      <Edit {...props} redirect="list">
        <SimpleForm>
          <TextInput source="name" />
          <SelectInput
            source="type"
            choices={[
              {
                id: "country",
                name: translate("resources.areas.type.country"),
              },
              { id: "region", name: translate("resources.areas.type.region") },
              { id: "city", name: translate("resources.areas.type.city") },
              {
                id: "district",
                name: translate("resources.areas.type.district"),
              },
            ]}
          />
          <NumberInput source="priority" />
          <ReferenceInput source="upperId" reference="areas">
            <SelectInput translateChoice={false} optionText="name" />
          </ReferenceInput>
          <ArrayInput source="locales">
            <SimpleFormIterator disableRemove disableAdd>
              <TextInput source="localeKey" label="Locale" disabled />
              <TextInput source="value" label="Value" />
            </SimpleFormIterator>
          </ArrayInput>
        </SimpleForm>
      </Edit>
    </>
  );
};
