import * as React from "react";
import { Edit, SimpleForm, useTranslate } from "react-admin";
import { TextInput, NumberInput } from "../../components/Inputs";
import { Grid, Typography } from "@mui/material";

export const ProductsEdit = (props) => {
  const translate = useTranslate();
  return (
    <>
      <Typography variant="h2">{translate("resources.products.edit")}</Typography>
      <Edit {...props} redirect="list">
        <SimpleForm>
          <Grid container spacing={2}>
            <Grid item md={6}>
              <TextInput source="id" disabled />
            </Grid>
            <Grid item md={6}>
              <TextInput source="name" />
              <TextInput source="units" />
              <TextInput source="productCode" />
              <TextInput source="unitsCode" />
              <NumberInput source="vat" />
            </Grid>
          </Grid>
        </SimpleForm>
      </Edit>
    </>
  );
};
