import * as React from "react";
import { useLocation } from "react-router";
import { Card, Grid } from "@mui/material";
import config from "../../config.json";
import {
  SimpleForm,
  Create,
  useTranslate,
  ArrayInput,
  SimpleFormIterator,
  Button,
  Form,
  useRedirect,
} from "react-admin";
import {
  TextInput,
  ReferenceInput,
  SelectInput,
  NumberInput,
} from "../../components/Inputs";

export const FPlaceCreate = (props) => {
  const location = useLocation();
  const fStationId =
    location.state && location.state.record
      ? location.state.record.fStationId
      : undefined;

  const redirect = fStationId ? `/fstations/${fStationId}/show/places` : false;
  const translate = useTranslate();
  const [data, setData] = React.useState();
  const getRedirect = useRedirect();
  React.useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    fetch(`${config.API_URL}/fstations/${fStationId}/fplaces`, {
      signal: signal,
    })
      .then((res) => res.json())
      .then((data) => {
        setData(data.length + 1);
      });
    return () => {
      // cancel the request before component unmounts
      controller.abort();
    };
  });
  const [open, setOpen] = React.useState(true);
  const openAutoGeneratePage = () => {
    setOpen(false);
  };
  const autoGeneratePlaces = (currentData) => {
    console.log(currentData);
    fetch(`${config.API_URL}/fplaces/${fStationId}/generatefplaces`, {
      method: "POST",
      body: JSON.stringify({
        fStationId: currentData.fStationId,
        countPlaces: currentData.countPlaces,
        countNozzles: currentData.countNozzles,
        productId: currentData.productId,
      }),
      headers: {
        "content-type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          getRedirect(redirect);
        }
      });
  };

  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h2>{translate("resources.fplaces.create")}</h2>
        <Button
          disabled={data > 1}
          onClick={openAutoGeneratePage}
          variant="contained"
        >
          Auto Generate places
        </Button>
      </div>
      {open ? (
        <Create {...props} redirect={redirect}>
          <SimpleForm margin={1}>
            <Grid container spacing={2}>
              <Grid item md={6}>
                <ReferenceInput
                  style={{ margin: 0 }}
                  source="fStationId"
                  reference="fstations"
                >
                  <SelectInput
                    style={{ margin: 0 }}
                    disabled={!!fStationId}
                    translateChoice={false}
                    optionText="name"
                  />
                </ReferenceInput>
              </Grid>
              <Grid item md={6}>
                <TextInput source="name" />
              </Grid>
              <Grid item md={6}>
                <NumberInput defaultValue={data} disabled source="order" />
              </Grid>

              <ArrayInput source="nozzles">
                <SimpleFormIterator>
                  <ReferenceInput source="productId" reference="products">
                    <SelectInput translateChoice={false} optionText="name" />
                  </ReferenceInput>
                  <NumberInput source="number" />
                </SimpleFormIterator>
              </ArrayInput>
            </Grid>
          </SimpleForm>
        </Create>
      ) : (
        <Card style={{ padding: "1rem", marginTop: "1rem" }}>
          <Form onSubmit={autoGeneratePlaces}>
            <Grid container spacing={2}>
              <Grid item md={6}>
                <ReferenceInput
                  source="fStationId"
                  style={{ margin: 0 }}
                  disabled={!!fStationId}
                  reference="fStations"
                >
                  <SelectInput style={{ margin: 0 }} source="name" />
                </ReferenceInput>
              </Grid>
              <Grid item md={6}>
                <TextInput source="countPlaces" />
              </Grid>
              <Grid item md={6}>
                <TextInput source="countNozzles" />
              </Grid>
              <Grid item md={6}>
                <ReferenceInput
                  source="productId"
                  style={{ margin: 0 }}
                  reference="products"
                >
                  <SelectInput style={{ margin: 0 }} source="name" />
                </ReferenceInput>
              </Grid>
            </Grid>
            <Button type="submit" size="medium" variant="contained">
              {translate("ra.action.create")}
            </Button>
          </Form>
        </Card>
      )}
    </>
  );
};
