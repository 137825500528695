import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  DeleteButton,
  useTranslate,
} from "react-admin";

export const TenantsList = (props) => {
  const translate = useTranslate();
  return (
    <>
      <h2>{translate("resources.tenants.list")}</h2>
      <List {...props}>
        <Datagrid rowClick="edit" size="medium">
          <TextField source="name" />
          <TextField source="identifier" />
          <TextField source="address" />
          <TextField source="phone" />
          <TextField source="executive" />
          <EditButton />
          <DeleteButton />
        </Datagrid>
      </List>
    </>
  );
};
