import { Grid } from "@mui/material";
import * as React from "react";
import {
  ArrayInput,
  Edit,
  SimpleForm,
  SimpleFormIterator,
  useTranslate,
} from "react-admin";
import { NumberInput, TextInput } from "../../components/Inputs";

export const AutoTypesEdit = (props) => {
  const translate = useTranslate();
  return (
    <>
      <h2>{translate("resources.autotypes.edit")}</h2>
      <Edit {...props} redirect="list">
        <SimpleForm>
          <TextInput source="id" disabled />
          <Grid container spacing={2}>
            <Grid item md={6}>
              <TextInput source="name" />
            </Grid>
            <Grid item md={6}>
              <NumberInput source="priority" />
            </Grid>
            <Grid item md={12}>
              <ArrayInput source="locales">
                <SimpleFormIterator disableRemove disableAdd>
                  <TextInput source="localeKey" label="Locale" disabled />
                  <TextInput source="value" label="Value" />
                </SimpleFormIterator>
              </ArrayInput>
            </Grid>
          </Grid>
        </SimpleForm>
      </Edit>
    </>
  );
};
