import * as React from "react";
import { Grid } from "@mui/material";
import {
  CheckboxGroupInput,
  Edit,
  SimpleForm,
  useTranslate,
} from "react-admin";

import {
  TextInput,
  SelectInput,
  ReferenceInput,
  ReferenceArrayInput,
} from "../../components/Inputs";

export const FStationEdit = (props) => {
  const translate = useTranslate();
  return (
    <>
      <h2>{translate("resources.fstations.edit")}</h2>
      <Edit {...props}>
        <SimpleForm>
          <TextInput disabled source="id" />
          <Grid container spacing={2}>
            <Grid item md={6}>
              <TextInput source="name" />
            </Grid>
            <Grid item md={6}>
              <ReferenceInput
                style={{ margin: 0 }}
                source="areaId"
                reference="areas"
              >
                <SelectInput
                  style={{ margin: 0 }}
                  translateChoice={false}
                  optionText="name"
                />
              </ReferenceInput>
            </Grid>
            <Grid item md={6}>
              <ReferenceArrayInput
                source="paymentTypes"
                reference="paymenttypes"
                allowEmpty
                filterToQuery={(s) => ({ q: s })}
              >
                <CheckboxGroupInput optionText="name" />
              </ReferenceArrayInput>
            </Grid>
            <Grid item md={12}>
              <TextInput multiline source="address" rows="4" />
            </Grid>
          </Grid>
        </SimpleForm>
      </Edit>
    </>
  );
};
