import * as React from "react";
import { SimpleForm, Create, useTranslate } from "react-admin";
import { TextInput } from "../../components/Inputs";

export const SuppliersCreate = (props) => {
  const translate = useTranslate();

  return (
    <>
      <h2>{translate("resources.suppliers.create")}</h2>
      <Create {...props} redirect="list">
        <SimpleForm>
          <TextInput source="name" />
          {/* another fields */}
        </SimpleForm>
      </Create>
    </>
  );
};
