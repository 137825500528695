import * as React from "react";
import { Edit, SimpleForm, useTranslate } from "react-admin";
import { TextInput } from "../../components/Inputs";
import { Grid } from "@mui/material";
import { ReferenceInput, SelectInput } from "./../../components/Inputs";

export const AutoEdit = (props) => {
  const translate = useTranslate();
  return (
    <>
      <h2>{translate("resources.autos.edit")}</h2>
      <Edit {...props} redirect="list">
        <SimpleForm>
          <Grid container spacing={2}>
            <Grid item md={6}>
              <ReferenceInput
                source="clientId"
                style={{ margin: 0 }}
                reference="clients"
              >
                <SelectInput style={{ margin: 0 }} source="name" />
              </ReferenceInput>
            </Grid>
            <Grid item md={6}>
              <TextInput source="number" />
            </Grid>
          </Grid>
        </SimpleForm>
      </Edit>
    </>
  );
};
