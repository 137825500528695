import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  DeleteButton,
  useTranslate,
} from "react-admin";

export const SuppliersList = (props) => {
  const translate = useTranslate();
  return (
    <>
      <h2>{translate("resources.suppliers.list")}</h2>
      <List {...props}>
        <Datagrid size="medium">
          <TextField source="id" />
          <TextField source="name" />
          {/* another fields */}
          <EditButton />
          <DeleteButton />
        </Datagrid>
      </List>
    </>
  );
};
