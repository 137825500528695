import { Grid } from "@mui/material";
import * as React from "react";
import { SimpleForm, Edit, useTranslate } from "react-admin";
import { ReferenceInput, TextInput } from "../../components/Inputs";
import { SelectInput } from "./../../components/Inputs";

export const SuppliesEdit = (props) => {
  const translate = useTranslate();

  return (
    <>
      <h2>{translate("resources.supplies.edit")}</h2>
      <Edit {...props} redirect="list">
        <SimpleForm>
          <Grid container spacing={2}>
            <Grid item md={6}>
              <ReferenceInput
                style={{ margin: 0 }}
                reference="suppliers"
                source="supplierId"
              >
                <SelectInput style={{ margin: 0 }} optionText="name" />
              </ReferenceInput>
            </Grid>
            <Grid item md={6}>
              <TextInput source="value" />
            </Grid>
            <Grid item md={6}>
              <SelectInput
                style={{ margin: 0 }}
                source="valueType"
                choices={[
                  {
                    id: "absolute",
                    name: translate("resources.supplies.type.absolute"),
                  },
                  {
                    id: "added",
                    name: translate("resources.supplies.type.added"),
                  },
                ]}
              />
            </Grid>
          </Grid>

          {/* another fields */}
        </SimpleForm>
      </Edit>
    </>
  );
};
