import * as React from "react";
import { Link } from "react-router-dom";
import {
  Show,
  TabbedShowLayout,
  Tab,
  TextField,
  ReferenceManyField,
  ReferenceField,
  Button,
  useRecordContext,
  useTranslate,
  ArrayField,
  FunctionField,
  useGetList,
  useLocaleState,
  Pagination
} from "react-admin";

import { FPlaceData } from "../fplace/fplace.list";
import { FCameraData } from "../fcamera/fcamera.list";
import { SyncData } from "../syncs/syncs.list";

const AddCameraButton = () => {
  const translate = useTranslate();
  const record = useRecordContext();
  return (
    <Button
      variant="text"
      component={Link}
      to={{
        pathname: "/fcameras/create",
        state: { record: { fStationId: record.id } },
      }}
      size="medium"
      label={translate("resources.fcameras.add")}
    />
  );
};
const AddPlaceButton = () => {
  const translate = useTranslate();
  const record = useRecordContext();
  return (
    <Button
      variant="text"
      component={Link}
      to={{
        pathname: "/fplaces/create",
        // Here we specify the initial record for the create view
        state: { record: { fStationId: record.id } },
      }}
      size="medium"
      label={translate("resources.fplaces.add")}
    />
  );
};

export const FStationShow = () => {
  const translate = useTranslate();

  const { data } = useGetList("paymenttypes", {
    page: 1,
    perPage: 10,
  });

  const getLocale = useLocaleState();
  return (
    <>
      <h2>{translate("resources.fstations.show")}</h2>
      <Show>
        <TabbedShowLayout>
          <Tab label={translate("static.info")}>
            <TextField source="id" />
            <TextField source="name" />
            <TextField source="address" />
            <ReferenceField source="areaId" reference="areas">
              <TextField source="name" />
            </ReferenceField>
            <ArrayField source="paymentTypes">
              <FunctionField
                render={(record) => {
                  return record.paymentTypes?.map((type) => {
                    return data?.map((item) => {
                      if (item.id === type) {
                        return item.locales.map((i) => {
                          if (i.localeKey === getLocale[0]) {
                            return (
                              <span
                                key={item.id}
                                style={{
                                  backgroundColor: "#DFE3E8",
                                  padding: "5px",
                                  margin: "5px 5px 5px 0",
                                  color: "black",
                                  fontSize: "13px",
                                  borderRadius: "1rem",
                                }}
                              >
                                {i.value}
                              </span>
                            );
                          }
                        });
                      }
                    });
                  });
                }}
              />
            </ArrayField>
          </Tab>

          <Tab label={translate("resources.fplaces.name")} path="places">
            <AddPlaceButton />

            <ReferenceManyField
              reference="fplaces"
              target="fstations"
              label={false}
            >
              <FPlaceData />
            </ReferenceManyField>
          </Tab>
          <Tab label={translate("resources.fcameras.name")} path="cameras">
            <AddCameraButton />

            <ReferenceManyField
              reference="fcameras"
              target="fstations"
              label={false}
            >
              <FCameraData />
            </ReferenceManyField>
          </Tab>
          <Tab label={translate("resources.syncs.name")} path="syncs">
            <ReferenceManyField
              reference="syncs"
              target="fstations"
              label={false}
              pagination={<Pagination />}
            >
              <SyncData />
            </ReferenceManyField>
          </Tab>
        </TabbedShowLayout>
      </Show>
    </>
  );
};
