export const uzbekMessage = {
  resources: {
    users: {
      name: "Foydalanuvchilar",
      list: "Foydalanuvchilar ro'yxati",
      create: "Foydalanuvchi yaratish",
      show: "Foydalanuvchi ma'lumotlari",
      edit: "Foydalanuvchilarni tahrirlash",
      fields: {
        firstName: "Ism",
        lastName: "Familiya",
        photo: "Rasm",
      },
    },
    areas: {
      name: "Hududlar",
      list: "Hududlar ro'yxati",
      create: "Hududlar yaratish",
      show: "Hududlar ma'lumotlari",
      edit: "Hududlarni tahrirlash",
      type: {
        country: "Davlat",
        region: "Viloyat",
        city: "Shahar",
        district: "Tuman",
      },
      fields: {
        name: "Nomi",
        priority: "Muhimlilik darajasi",
        type: "Turi",
        upperId: "Yuqori",
      },
    },
    clients: {
      name: "Kompaniya mijozlari",
      list: "Kompaniya mijozlari ro'yxati",
      create: "Kompaniya mijozlari yaratish",
      show: "Kompaniya mijozlari ma'lumotlari",
      edit: "Kompaniya mijozlari tahrirlash",
      statusType: {
        active: "Hozir ham mavjud",
        undefined: "Aniqlanmagan",
        ended: "Tugatilgan",
      },
      fields: {
        name: "Nomi",
        address: "Manzili",
        registeredAt: "Ro'yxatdan o'tgan sanasi",
        phone: "Telefon",
        status: "Status",
        executive: "Boshliq",
        stir: "Soliq to'lovchining identifikatsion raqami",
        thsht: "Tashkiliy-huquqiy shakllar tasniflagichi",
        dbibt: "Davlat va xo`jalik boshqaruvi idoralarini belgilash tizimi",
        ifut: " Iqtisodiy faoliyat turlari umumdavlat tasniflagichi",
        paymentTypeIds: "To'lov turlari",
      },
    },
    autos: {
      name: "Avtomobillar",
      list: "Avtomobillar ro'yxati",
      create: "Avtomobillar yaratish",
      show: "Avtomobillar ma'lumotlari",
      edit: "Avtomobillarni tahrirlash",
      child: "Avtomobillar ro'yxati",
      fields: {
        number: "Raqami",
        clientId: "Mijoz",
      },
    },
    autotypes: {
      name: "Avtomobil turlari",
      list: "Avtomobil turlari ro'yxati",
      create: "Avtomobil turlari yaratish",
      show: "Avtomobil turlari ma'lumotlari",
      edit: "Avtomobil turlarini tahrirlash",
      fields: {
        name: "Nomi",
        priority: "Muhimlilik darajasi",
      },
    },
    fcameras: {
      name: "Kameralar",
      list: "Kameralar ro'yxati",
      create: "Kameralar yaratish",
      show: "Kameralar ma'lumotlari",
      edit: "Kameralarni tahrirlash",
      add: "Kameralar qo'shish",
      fields: {
        id: "id",
        updatedAt: "Yangilangan vaqti",
        createdAt: "Yaratilgan vaqti",
        type: "Turi",
        fStationId: "Stansiya",
        placeId: "Joy",
        address: "Manzil",
        port: "Port",
        channel: "Kanal",
        username: "Foydalanuvchi",
        password: "Parol",
      },
    },
    fdispencers: {
      name: "Dispanserlar",
      item: "Dispanser",
      list: "Dispanserlar ro'yxati",
      create: "Dispanserlar yaratish",
      show: "Dispanserlar ma'lumotlari",
      edit: "Dispanserlarni tahrirlash",
      add: "Dispanserlar qo'shish",
      fields: {
        updatedAt: "Yangilangan vaqti",
        createdAt: "Yaratilgan vaqti",
        number: "Raqami",
        fStationId: "Stansiya",
        nozzles: "Gaz quvuri jo'mragi",
        fnozzles: {
          number: "Raqami",
          dispencerId: "Dispanser",
          dispencerNumber: "Dispanser raqami",
          productId: "Mahsulot",
          placeId: "Joy",
        },
      },
    },
    fnozzles: {
      name: "Gaz quvuri jo'mragi",
      list: "Gaz quvuri jo'mragi ro'yxati",
      create: "Gaz quvuri jo'mragi yaratish",
      show: "Gaz quvuri jo'mragi ma'lumotlari",
      edit: "Gaz quvuri jo'mragi tahrirlash",
      add: "Gaz quvuri jo'mragi qo'shish",
      fields: {
        updatedAt: "Yangilangan vaqti",
        createdAt: "Yaratilgan vaqti",
        number: "Raqami",
        dispencerNumber: "Dispanser raqami",
        dispencerId: "Dispanser",
        productId: "Mahsulot",
      },
    },
    fplaces: {
      name: "Joylar",
      list: "Joylar ro'yxati",
      create: "Joylar yaratish",
      show: "Joylar ma'lumotlari",
      edit: "Joylarni tahrirlash",
      add: "Joylar qo'shish",
      fields: {
        id: "id",
        updatedAt: "Yangilangan vaqti",
        createdAt: "Yaratilgan vaqti",
        fStationId: "Stansiya",
        name: "Nomi",
        order: "Navbat",
        nozzles: "Gaz quvuri jo'mragi",
      },
    },
    products: {
      name: "Mahsulotlar",
      list: "Mahsulotlar ro'yxati",
      create: "Mahsulotlar yaratish",
      show: "Mahsulotlar ma'lumotlari",
      edit: "Mahsulotlarni tahrirlash",
      fields: {
        id: "id",
        units: "O`lchov birligi",
        VAT: "Qo`shimcha Qiymat Solig`i",
        updatedAt: "Yangilangan vaqti",
        createdAt: "Yaratilgan vaqti",
        name: "Nomi",
      },
    },
    suppliers: {
      name: "Gaz quyuvchilar",
      list: "Gaz quyuvchilar ro'yxati",
      create: "Gaz quyuvchilar yaratish",
      show: "Gaz quyuvchilar ma'lumotlari",
      edit: "Gaz quyuvchilarni tahrirlash",
      fields: {
        id: "id",
        updatedAt: "Yangilangan vaqti",
        createdAt: "Yaratilgan vaqti",
        name: "Nomi",
      },
    },
    tenants: {
      name: "Filiallar",
      list: "Filiallar ro'yxati",
      create: "Filiallar yaratish",
      show: "Filiallar ma'lumotlari",
      edit: "Filiallar tahrirlash",
      statusType: {
        active: "Hozir ham mavjud",
        undefined: "Aniqlanmagan",
        ended: "Tugatilgan",
      },
      fields: {
        name: "Nomi",
        address: "Manzili",
        registeredAt: "Ro'yxatdan o'tgan sanasi",
        phone: "Telefon",
        status: "Status",
        executive: "Boshliq",
        stir: "Soliq to'lovchining identifikatsion raqami",
        thsht: "Tashkiliy-huquqiy shakllar tasniflagichi",
        dbibt: "Davlat va xo`jalik boshqaruvi idoralarini belgilash tizimi",
        ifut: " Iqtisodiy faoliyat turlari umumdavlat tasniflagichi",
      },
    },
    fstations: {
      name: "Stansiyalar",
      list: "Stansiyalar ro'yxati",
      create: "Stansiyalar yaratish",
      show: "Stansiyalar ma'lumotlari",
      edit: "Stansiyalarni tahrirlash",
      fields: {
        id: "id",
        updatedAt: "Yangilangan vaqti",
        createdAt: "Yaratilgan vaqti",
        areaId: "Joy",
        name: "Nomi",
        address: "Manzil",
        paymentTypes: "To'lov turlari",
      },
    },
    productprices: {
      name: "Mahsulot narxlari",
      list: "Mahsulot narxlari ro'yxati",
      create: "Mahsulot narxlari yaratish",
      show: "Mahsulot narxlari ma'lumotlari",
      edit: "Mahsulot narxlarini tahrirlash",
      fields: {
        id: "id",
        updatedAt: "Yangilangan vaqti",
        createdAt: "Yaratilgan vaqti",
        startDate: "Boshlanish vaqti",
        endDate: "Tugallanish vaqti",
        price: "Narxi",
        productId: "Mahsulot",
        fStationId: "Stansiya",
        areaId: "Hudud",
        clientId: "Mijoz",
      },
    },
    supplies: {
      name: "supplies",
      list: "supplies ro'yxati",
      create: "supplies yaratish",
      show: "supplies ma'lumotlari",
      edit: "supplies tahrirlash",
      type: {
        absolute: "haqiqiy",
        added: "qo'shilgan",
      },
      fields: {
        id: "id",
        updatedAt: "Yangilangan vaqti",
        createdAt: "Yaratilgan vaqti",
        supplierId: "Yetkazib beruvchi",
        value: "Qiymat",
        valueType: "Qiymat turi",
      },
    },
    fsessions: {
      name: "Sessialar",
      list: "Sessialar ro'yxati",
      create: "Sessialar yaratish",
      show: "Sessialar ma'lumotlari",
      edit: "Sessialarni tahrirlash",
      fields: {
        updatedAt: "Yangilangan vaqti",
        createdAt: "Yaratilgan vaqti",
        fStationId: "Stansiya",
        placeId: "Joy",
        nozzleId: "Gaz quvuri jo'mragi",
        startTime: "Boshlanish vaqti",
        initType: "Boshlang'ich tur",
        initLimit: "Boshlang'ich limit",
        volume: "Qiymat",
        price: "Narxi",
        cashierId: "Kassachi",
        number: "Raqami",
        autoId: "Avtomobil",
        result: "Natijalar",
        results: {
          id: "Id",
          fsessions: "Sessiya",
          status: "Status",
          volume: "Qiymat",
          sum: "Narx",
        },
      },
    },
    fevents: {
      name: "Hodisalar",
      list: "Hodisalar ro'yxati",
      create: "Hodisalar yaratish",
      show: "Hodisalar ma'lumotlari",
      edit: "Hodisalarni tahrirlash",
      fields: {
        id: "Id",
        updatedAt: "Yangilangan vaqti",
        createdAt: "Yaratilgan vaqti",
        fStationId: "Stansiyalar",
        nozzleId: "Gaz quvuri jo'mragi",
        dispencerId: "Dispanserlar",
        cameraId: "Kameralar",
        type: "Turi",
        code: "Kod",
        message: "Xabar",
      },
    },
    fsessionpayments: {
      name: "Sessiya To'lovlari",
      list: "Sessiya To'lovlari ro'yxati",
      create: "Sessiya To'lovlari yaratish",
      show: "Sessiya To'lovlari ma'lumotlari",
      edit: "Sessiya To'lovlarini tahrirlash",
      fields: {
        id: "id",
        updatedAt: "Yangilangan vaqti",
        createdAt: "Yaratilgan vaqti",
        fStationId: "Stansiya",
        paymentTypeId: "To'lov turi",
        sum: "Summa",
        userId: "Foydalanuvchi",
      },
    },
    fsessionstates: {
      name: "Sessiya holati",
      list: "Sessiya holati ro'yxati",
      create: "Sessiya holati yaratish",
      show: "Sessiya holati ma'lumotlari",
      edit: "Sessiya holatini tahrirlash",
      fields: {
        id: "Id",
        updatedAt: "Yangilangan vaqti",
        createdAt: "Yaratilgan vaqti",
        fStationId: "Stansiya",
        volume: "Miqdori",
        status: "Status",
      },
    },
    fsessioncaptures: {
      name: "Sessiya suratlari",
      list: "Sessiya suratlari",
      create: "Sessiya suratlari > Создание",
      show: "Sessiya suratlari > Информация",
      edit: "Sessiya suratlari > Редактировние",
      fields: {
        updatedAt: "Yangilangan vaqti",
        createdAt: "Yaratilgan vaqti",
        fSessionId: "Sessiya",
        src: "Rasm",
        number: "Raqam",
        time: "Vaqt",
      },
    },
    ftotals: {
      name: "Jami",
      list: "Jami",
      fields: {
        id: "Id",
        updatedAt: "Yangilangan vaqti",
        createdAt: "Yaratilgan vaqti",
        placeId: "Joy",
        nozzleId: "Gaz quvuri jo'mragi",
        value: "Qiymat",
        valueType: "Qiymat turi",
      },
    },
    paymentTypes: {
      name: "To'lov turlari",
      list: "To'lov turlari ro'yxati",
      create: "To'lov turlari yaratish",
      show: "To'lov turlari malumotlari",
      edit: "To'lov turlarini tahrirlash",
      fields: {
        id: "Id",
        name: "Nomi",
        isPrintable: "Chekni chop etish?",
        priority: "Muhimlilik darajasi",
      },
    },
    syncs: {
      name: "Sinxronizatsiya",
      list: "Sinxronizatsiya",
      show: "Sinxronizatsiya > Ma'lumot",
      fields: {
        id: "Id",
        time: "Vaqti",
        fStationId: "Stansiya",
        status: "Status",
        errors: "Xatolar"
      },
    },
  
  },
  static: {
    info: "Ma'lumotlar",
    hello_world: "Salom, xush kelibsiz",
    install_HBO: "HBO o'rnatish",
    marks: "Belgilar",
    textErrInn: "Bunaqa kampaniya yo'q",
    tex_service: "Texnik xizmat ko'rsatish",
    acts_processed: "Qayta ishlangan aktlar",
    service_indicators: "Xizmat ko'rsatkichlari",
    service_indicator_percent: "Oldingi oyga nisbatan +43%",
    indicators_by_region: "Mintaqalar bo'yicha ko'rsatkichlar",
    service_maintenance: "Xizmatlar",
    app_title: "Dashboard | XKS Boshqaruv Panel",
    size: "O'lchamlar",
    errorValidateText: "STIR 9ta raqamdan iborat bo'lishi kerak",
    stirPlaceholder: "STIR kiriting",
    checkCompany: "Kompaniyani tekshiring",
    addNewCompany: "Yangi kompaniya qo'shish",
    length: "Uzunlik",
    length_error: "Uzunlikdagi xatolik",
    operating_pressure: "Ishchi bosimi",
    value: "Qiymat",
    temperature: "Harorat",
    operating_temperature: "Ishchi harorat",
    material: "Material",
    weight: "Og'irligi",
    hydroCheck_pressure: "Suv bosimi",
    certificate_number: "Sertifikat raqami",
    dashboard: "Boshqaruv paneli",
    account: {
      displayName: "Ivanjon Ivan o'g'li",
      email: "admin@markaz.uz",
      photoURL: "/static/mock-images/avatars/avatar_default.jpg",
    },
  },
  ra: {
    action: {
      add_filter: "Filtr qo'shish",
      add: "Qo'shish",
      back: "Orqaga",
      bulk_actions:
        "1ta tanlandi |||| %{smart_count} tanlandi |||| %{smart_count} tanlandi",
      cancel: "Bekor qilmoq",
      clear_input_value: "Tozalamoq",
      clone: "Ko'chirmoq",
      confirm: "Tasdiqlamoq",
      create: "Yaratmoq",
      delete: "O'chirish",
      edit: "Tahrirlamoq",
      export: "Yuklab olmoq",
      list: "Ro'yxat",
      refresh: "Yangilamoq",
      remove_filter: "Filtrni olib tashlash",
      remove: "O'chirish",
      save: "Saqlamoq",
      search: "Qidiruv",
      show: "Ko'rish",
      sort: "Tartiblash",
      undo: "Bekor qilish",
      unselect: "Tanlanmadi",
      expand: "Ochish uchun",
      close: "Yopish",
      open_menu: "Menuni Ochish",
      close_menu: "Menuni Yopish",
    },
    boolean: {
      true: "Ha",
      false: "Yo'q",
      null: "",
    },
    page: {
      create: "%{name} Yaratish",
      dashboard: "Dashboard",
      users: "Userlar",
      edit: "%{name} #%{id}",
      error: "Uzr xatolik, qaytadan urinib ko'ring",
      list: "%{name}",
      loading: "Yuklanmoqda...",
      not_found: "Hech narsa topilmadi",
      show: "%{name} #%{id}",
      empty: "Bo'sh %{name}.",
      invite: "Yana qo'shishni xohlaysizmi?",
    },
    input: {
      file: {
        upload_several:
          "Fayllarni shu yerga olib keling yoki tanlash uchun bosing.",
        upload_single:
          "Fayllarni shu yerga olib keling yoki tanlash uchun bosing.",
      },
      image: {
        upload_several:
          "Rasmlarni shu yerga olib keling yoki tanlash uchun bosing.",
        upload_single:
          "Rasmlarni shu yerga olib keling yoki tanlash uchun bosing.",
      },
      references: {
        all_missing: "Tegishli ma'lumotlar topilmadi",
        many_missing: "Ba'zi tegishli ma'lumotlar mavjud emas",
        single_missing: "Tegishli ma'lumot mavjud emas",
      },
      password: {
        toggle_visible: "Parolni yashirish",
        toggle_hidden: "Parolni ko'rsatish",
      },
    },
    message: {
      about: "Malumot",
      are_you_sure: "Ishonchingiz komilmi?",
      bulk_delete_content:
        "Haqiqatan ham %{name}ni o'chirib tashlamoqchimisiz ? |||| Haqiqatan ham  %{smart_count}ta malumotni o'chirmoqchimisiz? ||||  Haqiqatan ham  %{smart_count}ta malumotni o'chirmoqchimisiz?",
      bulk_delete_title:
        "%{name} o'chirish ||||  %{smart_count} ta %{name}ni o'chirish |||| %{smart_count} ta %{name}ni o'chirish",
      delete_content: "Haqiqatan ham bu elementni o'chirib tashlamoqchimisiz?",
      delete_title: "%{name} #%{id} o'chirish",
      details: "Tafsilotlar",
      error: "So‘rov vaqtida xatolik yuz berdi va uni yakunlab bo‘lmaydi",
      invalid_form: "Forma noto'g'ri to'ldirilgan, xatolarni tekshiring",
      loading: "Yuklanmoqda, kuting...",
      no: "Yo'q",
      not_found: "URL xatosi yoki siz noto'g'ri havolaga o'tyapsiz",
      yes: "Ha",
      unsaved_changes:
        "Baʼzi oʻzgarishlaringiz saqlanmadi. Haqiqatan ham ularni e'tiborsiz qoldirmoqchimisiz?",
    },
    navigation: {
      no_results: "Hech qanday natija topilmadi",
      no_more_results: "%{page}-sahifa soni yo‘q, oldingisini sinab ko‘ring",
      page_out_of_boundaries: "%{page}-sahifa chegaradan tashqarida",
      page_out_from_end: "Oxirgi sahifadan o‘tib bo‘lmaydi",
      page_out_from_begin: "Sahifa raqami 1 dan kam boʻlmasligi kerak",
      page_range_info: "%{offsetBegin}-%{offsetEnd} dan %{total}",
      page_rows_per_page: "Har bir sahifadagi qatorlar:",
      next: "Keyingisi",
      prev: "Oldingi",
      skip_nav: "Tarkibga o‘tish",
    },
    sort: {
      sort_by: "%{field} %{order} kabi saralash",
      ASC: "tepaga",
      DESC: "pastga",
    },
    auth: {
      auth_check_error: "Ishni davom ettirish uchun tizimga kiring.",
      user_menu: "Profil",
      username: "Foydalanuvchi nomi",
      password: "Parol",
      sign_in: "Kirish",
      sign_in_error: "Autentifikatsiya amalga oshmadi, qayta urinib ko‘ring",
      logout: "Chiqish",
    },
    notification: {
      updated:
        "Element yangilandi |||| %{smart_count} yangilandi |||| %{smart_count} yangilandi",
      created: "Element yaratildi",
      deleted:
        "Element o'chirildi |||| %{smart_count} o'chirildi |||| %{smart_count} o'chirildi",
      bad_item: "Element yaroqsiz",
      item_doesnt_exist: "Element mavjud emas",
      http_error: "server xatosi",
      data_provider_error: "dataProvider xatosi, konsolni tekshiring",
      i18n_error: "Belgilangan til uchun tarjimani yuklab bo‘lmadi",
      canceled: "Operatsiya bekor qilindi",
      logged_out:
        "Seansingiz tugadi, qayta ulanishga/kirishga qaytadan urinib koʻring",
    },
    validation: {
      required: "Formani to'ldirish shart",
      minLength: "Belgilarning minimal soni %{min}",
      maxLength: "Belgilarning maksimal soni %{max}",
      minValue: "Minimal qiymat %{min}",
      maxValue: "Qiymat %{max} yoki undan kam boʻlishi mumkin",
      number: "Raqam bo'lishi kerak",
      email: "Noto'g'ri elektron pochta",
      oneOf: "Quyidagilardan biri boʻlishi kerak: %{options}",
      regex: "Quyidagi formatda bo'lishi kerak (regexp): %{pattern}",
    },
  },
};
