import { Divider } from "@mui/material";
import * as React from "react";
import {
  Show,
  TabbedShowLayout,
  Tab,
  TextField,
  ReferenceManyField,
  ReferenceField,
  useTranslate,
  DateField,
} from "react-admin";
import { FSessionCapturesData } from "../fsessioncapture/fsessioncapture.list";

import { FSessionPaymentsData } from "./../fsessionpayments/fsessionpayments.list";
import { FSessionStatesData } from "./../fsessionstates/fsessionstates.list";

export const FSessionsShow = () => {
  const translate = useTranslate();
  return (
    <>
      <h2>{translate("resources.fsessions.show")}</h2>
      <Show>
        <TabbedShowLayout>
          <Tab label={translate("static.info")}>
            <TextField source="id" />
            <ReferenceField reference="fstations" source="fStationId">
              <TextField source="name" />
            </ReferenceField>
            <ReferenceField reference="fnozzles" source="nozzleId">
              <TextField source="number" />
            </ReferenceField>
            <ReferenceField reference="fplaces" source="placeId">
              <TextField source="name" />
            </ReferenceField>
            <DateField source="startTime" />
            <TextField source="initType" />
            <TextField source="initLimit" />
            <TextField source="cashierId" />
            <TextField source="price" />
            <TextField source="number" />
            <ReferenceField reference="autos" source="autoId">
              <TextField source="number" />
            </ReferenceField>
            <TextField
              source="result"
              label={translate("resources.fsessions.fields.result")}
            />
            <TextField
              source="result.id"
              label={translate("resources.fsessions.fields.results.id")}
            />
            <ReferenceField
              reference="fsessions"
              label={translate("resources.fsessions.fields.results.fsessions")}
              source="result.fSessionId"
            >
              <TextField source="number" />
            </ReferenceField>
            <TextField
              source="result.status"
              label={translate("resources.fsessions.fields.results.status")}
            />
            <TextField
              source="result.volume"
              label={translate("resources.fsessions.fields.results.volume")}
            />
            <TextField
              source="result.sum"
              label={translate("resources.fsessions.fields.results.sum")}
            />
          </Tab>

          {/* <Tab
            label={translate("resources.fsessionpayments.name")}
            path="fsessionpayments"
          >
            <ReferenceManyField
              reference="fsessionpayments"
              target="fsessions"
              label={false}
            >
              <FSessionPaymentsData />
            </ReferenceManyField>
          </Tab>
          <Tab
            label={translate("resources.fsessionstates.name")}
            path="fsessionstates"
          >
            <ReferenceManyField
              reference="fsessionstates"
              target="fsessions"
              label={false}
            >
              <FSessionStatesData />
            </ReferenceManyField>
          </Tab>
          <Tab
            label={translate("resources.fsessioncaptures.name")}
            path="fsessioncaptures"
          >
            <ReferenceManyField
              reference="fsessioncaptures"
              target="fsessions"
              label={false}
            >
              <FSessionCapturesData />
            </ReferenceManyField>
          </Tab> */}
        </TabbedShowLayout>
      </Show>
    </>
  );
};
