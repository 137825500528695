import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  EditButton,
  DeleteButton,
  useTranslate,
  FunctionField,
} from "react-admin";
import {
  ReferenceInput,
  SelectInput,
  TextInput,
} from "../../components/Inputs";

const filters = [
  <TextInput source="q" label="Search" alwaysOn variant="outlined" />,
  <ReferenceInput source="parentId" label="SubAreas" reference="areas">
    <SelectInput optionText="name" variant="outlined" />
  </ReferenceInput>,
];

export const AreaList = (props) => {
  const translate = useTranslate();
  return (
    <>
      <h2>{translate("resources.areas.list")}</h2>
      <List {...props} filters={filters}>
        <Datagrid size="medium">
          <TextField source="id" />
          <TextField source="name" />
          <FunctionField
            label={`${translate("resources.areas.fields.type")}`}
            render={(record) =>
              `${
                record.type === "Country"
                  ? translate("resources.areas.type.country")
                  : record.type === "Region"
                  ? translate("resources.areas.type.region")
                  : record.type === "City"
                  ? translate("resources.areas.type.city")
                  : translate("resources.areas.type.district")
              }`
            }
          />
          <ReferenceField source="upperId" reference="areas">
            <TextField source="name" />
          </ReferenceField>
          <EditButton />
          <DeleteButton />
        </Datagrid>
      </List>
    </>
  );
};
