import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  EditButton,
  DeleteButton,
  useTranslate,
  SingleFieldList,
  ArrayField,
  ChipField,
} from "react-admin";
import {
  // ReferenceInput,
  TextInput,
  // SelectInput,
} from "../../components/Inputs";

const filters = [
  <TextInput source="q" label="Search" alwaysOn />,
  // <ReferenceInput source="mServiceId" label="Cameras" reference="mservices">
  //   <SelectInput optionText="name" />
  // </ReferenceInput>,
];

export const FPlaceData = (props) => {
  return (
    <Datagrid {...props} size="medium" rowClick={"show"}>
      <ReferenceField source="fStationId" reference="fstations">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="name" />
      <TextField source="order" />

      <ArrayField source="nozzles">
        <SingleFieldList>
          <ChipField source="number" />
        </SingleFieldList>
      </ArrayField>
      <EditButton />
      <DeleteButton />
    </Datagrid>
  );
};
export const FPlaceList = (props) => {
  const translate = useTranslate();

  return (
    <>
      <h2>{translate("resources.fplaces.list")}</h2>
      <List {...props} filters={filters}>
        <FPlaceData />
      </List>
    </>
  );
};
