import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  DeleteButton,
  useTranslate,
  BooleanField,
} from "react-admin";
import {
  ReferenceInput,
  SelectInput,
  TextInput,
} from "../../components/Inputs";

const filters = [
  <TextInput source="q" label="Search" alwaysOn variant="outlined" />,
  <ReferenceInput source="parentId" label="SubAreas" reference="areas">
    <SelectInput optionText="name" variant="outlined" />
  </ReferenceInput>,
];

export const PaymentTypesList = (props) => {
  const translate = useTranslate();
  return (
    <>
      <h2>{translate("resources.paymentTypes.list")}</h2>
      <List {...props} filters={filters}>
        <Datagrid size="medium">
          <TextField source="id" />
          <TextField source="name" />
          <TextField source="priority" />
          <BooleanField source="isPrintable" />
          <EditButton />
          <DeleteButton />
        </Datagrid>
      </List>
    </>
  );
};
