import * as React from "react";
import { Edit, SimpleForm, useTranslate } from "react-admin";
import { TextInput } from "../../components/Inputs";

export const SuppliersEdit = (props) => {
  const translate = useTranslate();
  return (
    <>
      <h2>{translate("resources.suppliers.edit")}</h2>
      <Edit {...props} redirect="list">
        <SimpleForm>
          <TextInput source="id" disabled />
          <TextInput source="name" />
          {/* another fields */}
        </SimpleForm>
      </Edit>
    </>
  );
};
