import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  EditButton,
  useTranslate,
  DeleteButton,
  DateField,
} from "react-admin";
import {
  ReferenceInput,
  TextInput,
  SelectInput,
} from "../../components/Inputs";

const filters = [
  <TextInput source="q" label="Search" alwaysOn />,
  <ReferenceInput source="fStationId" label="Station" reference="fstations">
    <SelectInput optionText="name" />
  </ReferenceInput>,
];

export const SyncData = (props) => (
  <Datagrid {...props} size="medium">
    
    <ReferenceField source="fStationId" reference="fstations">
      <TextField source="name" />
    </ReferenceField>
    <DateField source="time" showTime />
    <TextField source="status" />
  </Datagrid>
);
export const SyncList = (props) => {
  const translate = useTranslate();
  return (
    <>
      <h2>{translate("resources.syncs.list")}</h2>
      <List {...props} filters={filters}>
        <SyncData />
      </List>
    </>
  );
};
