import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  DeleteButton,
  useTranslate,
} from "react-admin";
import { TextInput } from "../../components/Inputs";

const filters = [
  <TextInput source="q" label="Search" alwaysOn />,
  // <ReferenceInput source="mServiceId" label="Cameras" reference="mservices">
  //   <SelectInput optionText="name" />
  // </ReferenceInput>,
];

export const AutoTypesList = (props) => {
  const translate = useTranslate();
  return (
    <>
      <h2>{translate("resources.autotypes.list")}</h2>
      <List {...props} filters={filters}>
        <Datagrid size="medium">
          <TextField source="id" />
          <TextField source="name" />
          <TextField source="priority" />
          <EditButton />
          <DeleteButton />
        </Datagrid>
      </List>
    </>
  );
};
