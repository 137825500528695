import * as React from "react";
import { Edit, SimpleForm, useTranslate } from "react-admin";
import { DateInput, TextInput } from "../../components/Inputs";
import Grid from "@mui/material/Grid";
import { SelectInput } from "./../../components/Inputs";

export const TenantsEdit = (props) => {
  const translate = useTranslate();
  return (
    <>
      <h2>{translate("resources.tenants.edit")}</h2>
      <Edit {...props}>
        <SimpleForm>
          <Grid container spacing={2}>
            <Grid item md={6}>
              <TextInput source="name" fullWidth />
            </Grid>
            <Grid item md={6}>
              <SelectInput
                source="status"
                fullWidth
                optionText="name"
                optionValue="id"
                defaultValue="active"
                choices={[
                  {
                    id: "active",
                    name: translate("resources.tenants.statusType.active"),
                  },
                  {
                    id: "undefined",
                    name: translate("resources.tenants.statusType.undefined"),
                  },
                  {
                    id: "ended ",
                    name: translate("resources.tenants.statusType.ended"),
                  },
                ]}
              />
            </Grid>
            <Grid item md={6}>
              <TextInput source="address" fullWidth />
            </Grid>
            <Grid item md={6}>
              <TextInput source="identifier" fullWidth />
            </Grid>
            <Grid item md={6}>
              <TextInput source="executive" fullWidth />
            </Grid>
            <Grid item md={6}>
              <TextInput source="phone" fullWidth />
            </Grid>
            <Grid item md={6}>
              <DateInput source="registeredAt" fullWidth />
            </Grid>

            <Grid item md={6}>
              <TextInput source="thsht" fullWidth />
            </Grid>

            <Grid item md={6}>
              <TextInput source="dbibt" fullWidth />
            </Grid>

            <Grid item md={6}>
              <TextInput source="ifut" fullWidth />
            </Grid>
          </Grid>
        </SimpleForm>
      </Edit>
    </>
  );
};
