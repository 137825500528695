import * as React from "react";
import { Link } from "react-router-dom";
import {
  Show,
  TabbedShowLayout,
  Tab,
  TextField,
  ReferenceManyField,
  ReferenceField,
  Button,
  useRecordContext,
  useTranslate,
} from "react-admin";

import { FNozzleData } from "./../fnozzles/fnozzles.list";

const AddFNozzleButton = () => {
  const translate = useTranslate();
  const record = useRecordContext();
  return (
    <Button
      variant="text"
      component={Link}
      to={{
        pathname: "/fnozzles/create",
        // Here we specify the initial record for the create view
        state: { record: { fplaceId: record.id } },
      }}
      size="medium"
      label={translate("resources.fnozzles.add")}
    />
  );
};

export const FPlaceShow = () => {
  const translate = useTranslate();
  return (
    <>
      <h2>{translate("resources.fplaces.show")}</h2>
      <Show>
        <TabbedShowLayout>
          <Tab label = {translate("static.info")} >
            <TextField source="id" />
            <TextField source="name" />
            <ReferenceField source="fStationId" reference="fstations">
              <TextField source="name" />
            </ReferenceField>
          </Tab>

          <Tab label={translate("resources.fnozzles.name")} path="fnozzles">
            <AddFNozzleButton />
            <ReferenceManyField
              reference="fnozzles"
              target="fplaces"
              label={false}
            >
              <FNozzleData />
            </ReferenceManyField>
          </Tab>
          
        </TabbedShowLayout>
      </Show>
    </>
  );
};
