import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  ReferenceField,
  DeleteButton,
  useTranslate,
  FunctionField,
} from "react-admin";

export const SuppliesList = (props) => {
  const translate = useTranslate();
  return (
    <>
      <h2>{translate("resources.supplies.list")}</h2>
      <List {...props}>
        <Datagrid size="medium">
          <TextField source="id" />
          <ReferenceField reference="suppliers" source="supplierId">
            <TextField source="name" />
          </ReferenceField>
          <TextField source="value" />
          <FunctionField
            label={`${translate("resources.supplies.fields.valueType")}`}
            render={(record) =>
              `${
                record.valueType === "Absolute"
                  ? translate("resources.supplies.type.absolute")
                  : translate("resources.supplies.type.added")
              }`
            }
          />
          <EditButton />
          <DeleteButton />
        </Datagrid>
      </List>
    </>
  );
};
