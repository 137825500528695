import * as React from "react";
import { Grid } from "@mui/material";
import { Edit, SimpleForm, useTranslate } from "react-admin";
import {
  TextInput,
  ReferenceInput,
  SelectInput,
  PasswordInput,
  NumberInput,
} from "../../components/Inputs";

export const FCameraEdit = (props) => {
  const translate = useTranslate();
  return (
    <>
      <h2>{translate("resources.fcameras.edit")}</h2>
      <Edit {...props}>
        <SimpleForm>
          <TextInput disabled source="id" />
          <Grid container spacing={2}>
            <Grid item md={6}>
              <SelectInput
                source="type"
                choices={[{ id: "DHIITC237", name: "DHIITC237" }]}
              />
            </Grid>
            <Grid item md={6}>
              <ReferenceInput source="fStationId" reference="fstations">
                <SelectInput translateChoice={false} optionText="name" />
              </ReferenceInput>
            </Grid>

            <Grid item md={6}>
              <ReferenceInput source="placeId" reference="fplaces">
                <SelectInput translateChoice={false} optionText="name" />
              </ReferenceInput>
            </Grid>
            <Grid item md={6}>
              <TextInput source="address" />
            </Grid>
            <Grid item md={6}>
              <TextInput source="username" />
            </Grid>
            <Grid item md={6}>
              <PasswordInput source="password" />
            </Grid>
            <Grid item md={6}>
              <NumberInput source="channel" />
            </Grid>
            <Grid item md={6}>
              <NumberInput source="port" />
            </Grid>
          </Grid>
        </SimpleForm>
      </Edit>
    </>
  );
};
