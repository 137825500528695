import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  useTranslate,
  ReferenceField,
} from "react-admin";
import { TextInput } from "../../components/Inputs";

const filters = [
  <TextInput source="q" label="Search" alwaysOn />,
  // <ReferenceInput source="mServiceId" label="Cameras" reference="mservices">
  //   <SelectInput optionText="name" />
  // </ReferenceInput>,
];

export const FSessionPaymentsData = (props) => {
  return (
    <Datagrid size="medium">
      <TextField source="id" />
      <ReferenceField source="fSessionId" reference="fsessions">
        <TextField source="number" />
      </ReferenceField>
      <TextField source="sum" />
      <TextField source="paymentTypeId" />
      <TextField source="userId" />

      {/* <ReferenceField source="userId" reference="users">
        <TextField source="firstName" />
      </ReferenceField> */}
    </Datagrid>
  );
};
export const FSessionPaymentsList = (props) => {
  const translate = useTranslate();
  return (
    <>
      <h2>{translate("resources.fSessionPayments.list")}</h2>
      <List {...props} filters={filters}>
        <FSessionPaymentsData />
      </List>
    </>
  );
};
