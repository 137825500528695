// component
import { useTranslate } from "react-admin";
import Iconify from "../../components/Iconify";

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

export const SidebarConfig = () => {
  const translate = useTranslate();
  return [
    {
      title: translate("static.dashboard"),
      path: "/",
      isMain: true,
      icon: getIcon("eva:pie-chart-2-fill"),
    },
    // {
    //   title: translate("resources.users.name"),
    //   path: "/users",
    //   icon: getIcon("eva:people-fill"),
    // },
    {
      title: translate("resources.areas.name"),
      path: "/areas",
      icon: getIcon("majesticons:map-marker-area"),
    },
    {
      title: translate("resources.autos.name"),
      icon: getIcon("fontisto:automobile"),
      children: [
        {
          title: translate("resources.autos.child"),
          path: "/autos",
          icon: getIcon("fontisto:automobile"),
        },
        {
          title: translate("resources.autotypes.name"),
          path: "/autotypes",
          icon: getIcon("simple-icons:rimacautomobili"),
        },
      ],
    },
    {
      title: translate("resources.fstations.name"),
      path: "/fstations",
      icon: getIcon("carbon:gas-station-filled"),
    },
    {
      title: translate("resources.products.name"),
      icon: getIcon("mdi:gas-burner"),
      children: [
        {
          title: translate("resources.products.name"),
          path: "/products",
          icon: getIcon("mdi:gas-burner"),
        },
        {
          title: translate("resources.productprices.name"),
          path: "/productprices",
          icon: getIcon("ic:outline-price-check"),
        },
        {
          title: translate("resources.paymentTypes.name"),
          path: "/paymenttypes",
          icon: getIcon("fluent:payment-16-filled"),
        },
      ],
    },
    {
      title: translate("resources.tenants.name"),
      path: "/tenants",
      icon: getIcon("mdi:office-building-marker-outline"),
    },
    {
      title: translate("resources.clients.name"),
      path: "/clients",
      icon: getIcon("fa6-solid:map-location-dot"),
    },

    {
      title: translate("resources.fsessions.name"),
      icon: getIcon("carbon:session-border-control"),
      path: "/fsessions",
    },

    {
      title: translate("resources.supplies.name"),
      path: "/supplies",
      icon: getIcon("mdi:gas-cylinder"),
      children: [
        {
          title: translate("resources.suppliers.name"),
          path: "/suppliers",
          icon: getIcon("map:gas-station"),
        },
        {
          title: translate("resources.supplies.name"),
          path: "/supplies",
          icon: getIcon("mdi:gas-cylinder"),
        },
      ],
    },
  ];
};
