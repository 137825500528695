import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  DateField,
  useTranslate,
} from "react-admin";
import { TextInput } from "../../components/Inputs";

const filters = [
  <TextInput source="q" label="Search" alwaysOn />,
  // <ReferenceInput source="mServiceId" label="Cameras" reference="mservices">
  //   <SelectInput optionText="name" />
  // </ReferenceInput>,
];

export const FSessionsList = (props) => {
  const translate = useTranslate();

  return (
    <>
      <h2>{translate("resources.fsessions.list")}</h2>
      <List {...props} filters={filters}>
        <Datagrid size="medium" rowClick={"show"}>
          {/* <TextField source="id" /> */}
          <ReferenceField reference="fstations" source="fStationId">
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField reference="fnozzles" source="nozzleId">
            <TextField source="number" />
          </ReferenceField>
          <ReferenceField reference="fplaces" source="placeId">
            <TextField source="name" />
          </ReferenceField>
          <DateField source="startTime" />
          <TextField source="initType" />
          <TextField source="initLimit" />
          <TextField source="cashierId" />
          <TextField source="price" />
          <TextField source="number" />
          <ReferenceField reference="autos" source="autoId">
            <TextField source="number" />
          </ReferenceField>
          {/* <DeleteButton /> */}
        </Datagrid>
      </List>
    </>
  );
};
