import * as React from "react";
import {
  CheckboxGroupInput,
  Edit,
  SimpleForm,
  useTranslate,
} from "react-admin";
import {
  TextInput,
  DateInput,
  SelectInput,
  ReferenceArrayInput,
} from "../../components/Inputs";
import { Grid, Typography } from "@mui/material";

export const ClientsEdit = (props) => {
  const translate = useTranslate();
  return (
    <>
      <Typography variant="h2">{translate("resources.clients.edit")}</Typography>
      <Edit {...props}>
        <SimpleForm>
          <Grid container spacing={2}>
            <Grid item md={6}>
              <TextInput source="name" fullWidth />
            </Grid>
            <Grid item md={6}>
              <SelectInput
                source="status"
                fullWidth
                optionText="name"
                optionValue="id"
                choices={[
                  {
                    id: "active",
                    name: translate("resources.clients.statusType.active"),
                  },
                  {
                    id: "undefined",
                    name: translate("resources.clients.statusType.undefined"),
                  },
                  {
                    id: "ended ",
                    name: translate("resources.clients.statusType.ended"),
                  },
                ]}
              />
            </Grid>
            <Grid item md={6}>
              <TextInput source="address" fullWidth />
            </Grid>
            <Grid item md={6}>
              <TextInput source="identifier" fullWidth />
            </Grid>
            <Grid item md={6}>
              <TextInput source="executive" fullWidth />
            </Grid>
            <Grid item md={6}>
              <TextInput source="phone" fullWidth />
            </Grid>
            <Grid item md={6}>
              <DateInput source="registeredAt" fullWidth />
            </Grid>

            {/* <Grid item md={6}>
              <TextInput source="thsht" fullWidth />
            </Grid>

            <Grid item md={6}>
              <TextInput source="dbibt" fullWidth />
            </Grid>

            <Grid item md={6}>
              <TextInput source="ifut" fullWidth />
            </Grid> */}
            <Grid item md={6}>
              <ReferenceArrayInput
                source="paymentTypeIds"
                reference="paymenttypes"
              >
                <CheckboxGroupInput
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      lineHeight: "2.4375em",
                    },
                  }}
                  style={{ margin: "0px" }}
                  optionText="name"
                />
              </ReferenceArrayInput>
            </Grid>
          </Grid>
        </SimpleForm>
      </Edit>
    </>
  );
};
