import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  useTranslate,
  ReferenceField,
} from "react-admin";
import { TextInput } from "../../components/Inputs";

const filters = [
  <TextInput source="q" label="Search" alwaysOn />,
  // <ReferenceInput source="mServiceId" label="Cameras" reference="mservices">
  //   <SelectInput optionText="name" />
  // </ReferenceInput>,
];

export const FEventsList = (props) => {
  const translate = useTranslate();
  return (
    <>
      <h2>{translate("resources.fevents.list")}</h2>
      <List {...props} filters={filters}>
        <Datagrid size="medium">
          <TextField source="id" />
          <ReferenceField source="fStationId" reference="fstations">
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField source="placeId" reference="fplaces">
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField source="nozzleId" reference="fnozzles">
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField source="cameraId" reference="fcameras">
            <TextField source="name" />
          </ReferenceField>
          <TextField source="type" />
          <TextField source="code" />
          <TextField source="message" />
        </Datagrid>
      </List>
    </>
  );
};
