import * as React from "react";
import { Edit, SimpleForm, useTranslate } from "react-admin";
import {
  DateTimeInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  TextInput,
} from "../../components/Inputs";
import { Grid } from "@mui/material";

export const ProductPricesEdit = (props) => {
  const translate = useTranslate();
  return (
    <>
      <h2>{translate("resources.productprices.edit")}</h2>
      <Edit {...props} redirect="list">
        <SimpleForm>
          <TextInput source="id" disabled />
          <Grid container spacing={2}>
            <Grid item md={6}>
              <DateTimeInput source="startDate" />
            </Grid>
            <Grid item md={6}>
              <DateTimeInput source="endDate" />
            </Grid>
            <Grid item md={6}>
              <NumberInput source="price" />
            </Grid>
            <Grid item md={6}>
              <ReferenceInput
                style={{ margin: 0 }}
                source="productId"
                reference="products"
              >
                <SelectInput style={{ margin: 0 }} source="name" />
              </ReferenceInput>
            </Grid>
            <Grid item md={6}>
              <ReferenceInput
                style={{ margin: 0 }}
                source="fStationId"
                reference="fstations"
              >
                <SelectInput style={{ margin: 0 }} source="name" />
              </ReferenceInput>
            </Grid>
            <Grid item md={6}>
              <ReferenceInput
                style={{ margin: 0 }}
                source="areaId"
                reference="areas"
              >
                <SelectInput style={{ margin: 0 }} source="name" />
              </ReferenceInput>
            </Grid>
            <Grid item md={6}>
              <ReferenceInput
                style={{ margin: 0 }}
                source="clientId"
                reference="clients"
              >
                <SelectInput style={{ margin: 0 }} source="name" />
              </ReferenceInput>
            </Grid>
          </Grid>
        </SimpleForm>
      </Edit>
    </>
  );
};
