export const russianMessage = {
  resources: {
    users: {
      name: "Пользователи",
      list: "Пользователи",
      create: "Пользователи > Создание",
      show: "Пользователи > Информация",
      edit: "Пользователи > Редактировние",
      fields: {
        id: "Ид",
        firstName: "Имя",
        lastName: "Фамилия",
        photo: "Фото",
      },
    },
    areas: {
      name: "Регионы",
      list: "Регионы",
      create: "Регионы > Создание",
      show: "Регионы > Информация",
      edit: "Регионы > Редактировние",
      type: {
        country: "Страна",
        region: "Область",
        city: "Город",
        district: "Pайон",
      },
      fields: {
        id: "Ид",
        name: "Название",
        priority: "Приоритет",
        type: "Тип",
        upperId: "Верхний",
      },
    },
    clients: {
      name: "Компании клиенты",
      list: "Компании клиенты",
      create: "Компании клиенты > Создание",
      show: "Компании клиенты > Информация",
      edit: "Компании клиенты > Редактировние",
      statusType: {
        active: "действующий",
        undefined: "неопределенный",
        ended: "оконченный",
      },
      fields: {
        id: "Ид",
        name: "Название",
        address: "Aдрес",
        registeredAt: "Зарегистрировано",
        phone: "Телефон",
        status: "Статус",
        executive: "Руководитель",
        stir: "Идентификационный номер налогоплательщика",
        thsht: "Классификатор организационно-правовых форм",
        dbibt:
          "Система назначения органов государственного и хозяйственного управления",
        ifut: "Национальная классификация видов экономической деятельности",
        paymentTypeIds: "Способ оплаты",
      },
    },
    autos: {
      name: "автомобили",
      list: "Aвтомобили",
      create: "Aвтомобили > Создание",
      show: "Aвтомобили > Информация",
      edit: "Aвтомобили > Редактировние",
      child: "Список автомобилей",
      fields: {
        id: "Ид",
        number: "Hомер",
        clientId: "Клиент",
      },
    },
    autotypes: {
      name: "Aвтотипы",
      list: "Aвтотипы",
      create: "Aвтотипы > Создание",
      show: "Aвтотипы > Информация",
      edit: "Aвтотипы > Редактировние",
      fields: {
        id: "Ид",
        name: "Название",
        priority: "Приоритет",
      },
    },
    fcameras: {
      name: "камеры",
      list: "Kамеры",
      create: "Kамеры > Создание",
      show: "Kамеры > Информация",
      edit: "Kамеры > Редактировние",
      add: "Добавить камеру",
      fields: {
        id: "Ид",
        updatedAt: "Oбновлено",
        createdAt: "Cоздано",
        type: "Tип",
        fStationId: "Станция",
        placeId: "Mеста",
        address: "Aдрес",
        port: "Порт",
        channel: "Канал",
        username: "Имя пользователя",
        password: "Пароль",
      },
    },
    fdispencers: {
      name: "Диспансеры",
      item: "Диспансер",
      list: "Диспансеры",
      create: "Диспансеры > Создание",
      show: "Диспансеры > Информация",
      edit: "Диспансеры > Редактировние",
      add: "Добавить Диспансер",
      fields: {
        id: "Ид",
        updatedAt: "Oбновлено",
        createdAt: "Cоздано",
        number: "Hомер",
        fStationId: "Станция",
        nozzles: "Заправочный пистолет",
        fnozzles: {
          id: "Ид",
          updatedAt: "Oбновлено",
          createdAt: "Cоздано",
          number: "Hомер",
          dispencerNumber: "Номер Диспансера",
          dispencerId: "Диспансер",
          productId: "Продукт",
        },
      },
    },
    fnozzles: {
      name: "Заправочный пистолет",
      list: "Заправочный пистолет",
      create: "Заправочный пистолет > Создание",
      show: "Заправочный пистолет > Информация",
      edit: "Заправочный пистолет > Редактировние",
      add: "Добавить Заправочный пистолет",
      fields: {
        id: "Ид",
        updatedAt: "Oбновлено",
        createdAt: "Cоздано",
        number: "Hомер",
        dispencerNumber: "Номер Диспансера",
        dispencerId: "Диспансер",
        productId: "Продукт",
      },
    },
    fplaces: {
      name: "Заправочное место",
      list: "Заправочные места",
      create: "Заправочных мест > Создание",
      show: "Заправочных мест > Информация",
      edit: "Заправочных мест > Редактировние",
      add: "Добавить Заправочное место",
      fields: {
        id: "Ид",
        updatedAt: "Oбновлено",
        createdAt: "Cоздано",
        name: "Название",
        fStationId: "Станция",
        order: "Порядок",
        nozzles: "Заправочный пистолет",
        fnozzles: {
          id: "Ид",
          updatedAt: "Oбновлено",
          createdAt: "Cоздано",
          number: "Hомер",
          dispencerNumber: "Номер Диспансера",
          dispencerId: "Диспансер",
          productId: "Продукт",
        },
      },
    },
    fstations: {
      name: "Cтанции",
      list: "Cтанции",
      create: "Cтанции > Создание",
      show: "Cтанции > Информация",
      edit: "Cтанции > Редактировние",
      fields: {
        id: "Ид",
        updatedAt: "Oбновлено",
        createdAt: "Cоздано",
        areaId: "Регион",
        name: "Название",
        address: "Aдрес",
        paymentTypes: "Способ оплаты",
      },
    },
    products: {
      name: "продукты",
      list: "Продукты",
      create: "Продукты > Создание",
      show: "Продукты > Информация",
      edit: "Продукты > Редактировние",
      fields: {
        id: "Ид",
        units: "Единица измирения",
        VAT: "НДС",
        updatedAt: "Oбновлено",
        createdAt: "Cоздано",
        name: "Название",
      },
    },
    suppliers: {
      name: "Поставщик",
      list: "Поставщик",
      create: "Поставщик > Создание",
      show: "Поставщик > Информация",
      edit: "Поставщик > Редактировние",
      fields: {
        id: "Ид",
        updatedAt: "Oбновлено",
        createdAt: "Cоздано",
        name: "Название",
      },
    },
    tenants: {
      name: "Компании",
      list: "Компании",
      create: "Компании > Создание",
      show: "Компании > Информация",
      edit: "Компании > Редактировние",
      statusType: {
        active: "действующий",
        undefined: "неопределенный",
        ended: "оконченный",
      },
      fields: {
        id: "Ид",
        name: "Название",
        address: "Адрес",
        registeredAt: "Зарегистрировано",
        phone: "Телефон",
        status: "Статус",
        executive: "Руководитель",
        stir: "Идентификационный номер налогоплательщика",
        thsht: "Классификатор организационно-правовых форм",
        dbibt:
          "Система назначения органов государственного и хозяйственного управления",
        ifut: "Национальная классификация видов экономической деятельности",
      },
    },
    supplies: {
      name: "Снабжение",
      list: "Снабжение",
      create: "Снабжение > Создание",
      show: "Снабжение > Информация",
      edit: "Снабжение > Редактировние",
      type: {
        absolute: "абсолютный",
        added: "добавленный",
      },
      fields: {
        id: "Ид",
        updatedAt: "Oбновлено",
        createdAt: "Cоздано",
        supplierId: "Поставщик",
        value: "Значение",
        valueType: "Тип Значение",
      },
    },
    productprices: {
      name: "Ценообразование",
      list: "Ценообразование",
      create: "Ценообразование > Создание",
      show: "Ценообразование > Информация",
      edit: "Ценообразование > Редактировние",
      fields: {
        id: "Ид",
        updatedAt: "Oбновлено",
        createdAt: "Cоздано",
        startDate: "Дата начала",
        endDate: "Дата окончания",
        price: "Цена",
        productId: "Продукт",
        fStationId: "Станция",
        areaId: "Регион",
        clientId: "Клиенты",
      },
    },
    fsessions: {
      name: "Сессии",
      list: "Сессии",
      create: "Сессии > Создание",
      show: "Сессии > Информация",
      edit: "Сессии > Редактировние",
      fields: {
        id: "Ид",
        updatedAt: "Oбновлено",
        createdAt: "Cоздано",
        fStationId: "Станция",
        placeId: "Заправочное место",
        nozzleId: "Заправочный пистолет",
        startTime: "Время начала",
        initType: "Исходный тип",
        initLimit: "Начальный предел",
        price: "Цена",
        cashierId: "Kассир",
        number: "Hомер",
        autoId: "Aвто",
        result: "Результат",
        results: {
          id: "Ид",
          fsessions: "Сессии",
          status: "Статус",
          volume: "Объем",
          sum: "Сумма",
        },
      },
    },
    fevents: {
      name: "События",
      list: "События",
      create: "События > Создание",
      show: "События > Информация",
      edit: "События > Редактировние",
      fields: {
        id: "Ид",
        updatedAt: "Oбновлено",
        createdAt: "Cоздано",
        fStationId: "Станция",
        nozzleId: "Заправочный пистолет",
        dispencerId: "Диспансер",
        cameraId: "Kамеры",
        type: "Тип",
        code: "Код",
        message: "Сообщение",
      },
    },
    fsessionpayments: {
      name: "Платежи",
      list: "Платежи",
      create: "Платежи > Создание",
      show: "Платежи > Информация",
      edit: "Платежи > Редактировние",
      fields: {
        id: "Ид",
        updatedAt: "Oбновлено",
        createdAt: "Cоздано",
        fStationId: "Станция",
        paymentTypeId: "Способ оплаты",
        sum: "Сумма",
        userId: "Кассир",
      },
    },
    fsessionstates: {
      name: "Состояния сессии",
      list: "Состояния сессии",
      create: "Состояния сессии > Создание",
      show: "Состояния сессии > Информация",
      edit: "Состояния сессии > Редактировние",
      fields: {
        id: "Ид",
        updatedAt: "Oбновлено",
        createdAt: "Cоздано",
        fStationId: "Станция",
        volume: "Объем",
        status: "Статус",
      },
    },
    fsessioncaptures: {
      name: "Снимок сессии",
      list: "Снимок сессии",
      create: "Снимок сессии > Создание",
      show: "Снимок сессии > Информация",
      edit: "Снимок сессии > Редактировние",
      fields: {
        id: "Ид",
        updatedAt: "Oбновлено",
        createdAt: "Cоздано",
        fSessionId: "сессия",
        src: "Изображение",
        number: "Номер",
        time: "время",
      },
    },
    ftotals: {
      name: "Общие показания",
      list: "Общие показания",
      create: "Общие показания > Создание",
      show: "Общие показания > Информация",
      edit: "Общие показания > Редактировние",
      fields: {
        id: "Ид",
        updatedAt: "Oбновлено",
        createdAt: "Cоздано",
        placeId: "Заправочное место",
        nozzleId: "Заправочный пистолет",
        value: "Значение",
        valueType: "Тип Значения",
      },
    },
    paymentTypes: {
      name: "Способы оплаты",
      list: "Способы оплаты",
      create: "Способы оплаты > Создание",
      show: "Способы оплаты > Информация",
      edit: "Способы оплаты > Редактировние",
      fields: {
        id: "Ид",
        isPrintable: "Печатать чек?",
        name: "Название",
        priority: "Приоритет",
      },
    },
    syncs: {
      name: "Синхронизации",
      list: "Синхронизации",
      show: "Синхронизации > Информация",
      fields: {
        id: "Ид",
        time: "Время",
        fStationId: "Станция",
        status: "Статус",
        errors: "Ощибки"
      },
    },
  },
  static: {
    info: "Информация",
    hello_world: "Привет, Добро пожаловать",
    install_HBO: "Установки ГБО",
    marks: "Маркировки",
    tex_service: "Техническое обслуживание",
    acts_processed: "Актов обработано",
    service_indicators: "Показатели по сервисному обслуживанию",
    service_indicator_percent: "+43% от предыдушего месяца",
    indicators_by_region: "Показатели по регионам",
    service_maintenance: "Сервисное обслуживание",
    app_title: "Dashboard | СТО Админ панель",
    dashboard: "панель управления",
    textErrInn: "Нет такой кампании",
    stirPlaceholder: "Введите ИНН",
    checkCompany: "Проверьте свою компанию",
    addNewCompany: "Добавить новую компанию",
    errorValidateText: "Строка должна состоять из 9 цифр",
    account: {
      displayName: "Иван Иванов",
      email: "admin@markaz.uz",
      photoURL: "/static/mock-images/avatars/avatar_default.jpg",
    },
  },
  ra: {
    action: {
      add_filter: "Добавить фильтр",
      add: "Добавить",
      back: "Назад",
      bulk_actions:
        "1 выбран |||| %{smart_count} выбрано |||| %{smart_count} выбрано",
      cancel: "Отмена",
      clear_input_value: "Очистить",
      clone: "Дублировать",
      confirm: "Подтвердить",
      create: "Создать",
      delete: "Удалить",
      edit: "Редактировать",
      export: "Экспорт",
      list: "Список",
      refresh: "Обновить",
      remove_filter: "Убрать фильтр",
      remove: "Удалить",
      save: "Сохранить",
      search: "Поиск",
      show: "Просмотр",
      sort: "Сортировка",
      undo: "Отменить",
      unselect: "Не выбрано",
      expand: "Раскрыть",
      close: "Закрыть",
      open_menu: "Открыть меню",
      close_menu: "Закрыть меню",
    },
    boolean: {
      true: "Да",
      false: "Нет",
      null: "",
    },
    page: {
      create: "Создать %{name}",
      dashboard: "Главная",
      edit: "%{name} #%{id}",
      error: "Что-то пошло не так",
      list: "%{name}",
      loading: "Загрузка",
      not_found: "Не найдено",
      show: "%{name} #%{id}",
      empty: "Нет %{name}.",
      invite: "Вы хотите добавить еще одну?",
    },
    input: {
      file: {
        upload_several: "Перетащите файлы сюда или нажмите для выбора.",
        upload_single: "Перетащите файл сюда или нажмите для выбора.",
      },
      image: {
        upload_several: "Перетащите изображения сюда или нажмите для выбора.",
        upload_single: "Перетащите изображение сюда или нажмите для выбора.",
      },
      references: {
        all_missing: "Связанных данных не найдено",
        many_missing: "Некоторые из связанных данных не доступны",
        single_missing: "Связанный объект не доступен",
      },
      password: {
        toggle_visible: "Скрыть пароль",
        toggle_hidden: "Показать пароль",
      },
    },
    message: {
      about: "Справка",
      are_you_sure: "Вы уверены?",
      bulk_delete_content:
        "Вы уверены, что хотите удалить %{name}? |||| Вы уверены, что хотите удалить объекты, кол-вом %{smart_count} ? |||| Вы уверены, что хотите удалить объекты, кол-вом %{smart_count} ?",
      bulk_delete_title:
        "Удалить %{name} |||| Удалить %{smart_count} %{name} |||| Удалить %{smart_count} %{name}",
      delete_content: "Вы уверены что хотите удалить этот объект",
      delete_title: "Удалить %{name} #%{id}",
      details: "Описание",
      error: "В процессе запроса возникла ошибка, и он не может быть завершен",
      invalid_form: "Форма заполнена неверно, проверьте, пожалуйста, ошибки",
      loading: "Идет загрузка, пожалуйста, подождите...",
      no: "Нет",
      not_found: "Ошибка URL или вы следуете по неверной ссылке",
      yes: "Да",
      unsaved_changes:
        "Некоторые из ваших изменений не были сохранены. Вы уверены, что хотите их игнорировать?",
    },
    navigation: {
      no_results: "Результатов не найдено",
      no_more_results:
        "Страница %{page} выходит за пределы нумерации, попробуйте предыдущую",
      page_out_of_boundaries: "Страница %{page} вне границ",
      page_out_from_end: "Невозможно переместиться дальше последней страницы",
      page_out_from_begin: "Номер страницы не может быть меньше 1",
      page_range_info: "%{offsetBegin}-%{offsetEnd} из %{total}",
      page_rows_per_page: "Строк на странице:",
      next: "Следующая",
      prev: "Предыдущая",
      skip_nav: "Перейти к содержанию",
    },
    sort: {
      sort_by: "Сортировать по %{field} %{order}",
      ASC: "возрастанию",
      DESC: "убыванию",
    },
    auth: {
      auth_check_error: "Пожалуйста, авторизуйтесь для продолжения работы",
      user_menu: "Профиль",
      username: "Имя пользователя",
      password: "Пароль",
      sign_in: "Войти",
      sign_in_error: "Ошибка аутентификации, попробуйте снова",
      logout: "Выйти",
    },
    notification: {
      updated:
        "Элемент обновлен |||| %{smart_count} обновлено |||| %{smart_count} обновлено",
      created: "Элемент создан",
      deleted:
        "Элемент удален |||| %{smart_count} удалено |||| %{smart_count} удалено",
      bad_item: "Элемент не валиден",
      item_doesnt_exist: "Элемент не существует",
      http_error: "Ошибка сервера",
      data_provider_error: "Ошибка dataProvider, проверьте консоль",
      i18n_error: "Не удалось загрузить перевод для указанного языка",
      canceled: "Операция отменена",
      logged_out:
        "Ваша сессия завершена, попробуйте переподключиться/войти снова",
    },
    validation: {
      required: "Обязательно для заполнения",
      minLength: "Минимальное кол-во символов %{min}",
      maxLength: "Максимальное кол-во символов %{max}",
      minValue: "Минимальное значение %{min}",
      maxValue: "Значение может быть %{max} или меньше",
      number: "Должно быть цифрой",
      email: "Некорректный email",
      oneOf: "Должно быть одним из: %{options}",
      regex: "Должно быть в формате (regexp): %{pattern}",
    },
  },
};
