import * as React from "react";
import { useLocation } from "react-router";
import { Grid } from "@mui/material";
import { SimpleForm, Create, useTranslate } from "react-admin";
import {
  TextInput,
  ReferenceInput,
  SelectInput,
  NumberInput,
  PasswordInput,
} from "../../components/Inputs";

export const FCameraCreate = (props) => {
  const location = useLocation();
  const fStationId =
    location.state && location.state.record
      ? location.state.record.fStationId
      : undefined;

  const redirect = fStationId ? `/fstations/${fStationId}/show/cameras` : false;
  const translate = useTranslate();

  return (
    <>
      <h2>{translate("resources.fcameras.create")}</h2>

      <Create {...props} redirect={redirect}>
        <SimpleForm margin={1}>
          <Grid container spacing={2}>
            <Grid item md={6}>
              <SelectInput
                source="type"
                choices={[{ id: "DHIITC237", name: "DHIITC237" }]}
              />
            </Grid>
            <Grid item md={6}>
              <ReferenceInput source="fStationId" reference="fstations">
                <SelectInput
                  disabled={!!fStationId}
                  translateChoice={false}
                  optionText="name"
                />
              </ReferenceInput>
            </Grid>

            <Grid item md={6}>
              <ReferenceInput source="placeId" reference="fplaces">
                <SelectInput translateChoice={false} optionText="name" />
              </ReferenceInput>
            </Grid>
            <Grid item md={6}>
              <TextInput source="address" />
            </Grid>
            <Grid item md={6}>
              <TextInput source="username" />
            </Grid>
            <Grid item md={6}>
              <PasswordInput source="password" />
            </Grid>
            <Grid item md={6}>
              <NumberInput source="channel" />
            </Grid>
            <Grid item md={6}>
              <NumberInput source="port" />
            </Grid>
          </Grid>
        </SimpleForm>
      </Create>
    </>
  );
};
