import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  DeleteButton,
  useTranslate,
} from "react-admin";
import // ReferenceInput,
// TextInput,
// SelectInput,
"../../components/Inputs";

// const filters = [
//   <TextInput source="q" label="Search" alwaysOn />,
//   // <ReferenceInput source="mServiceId" label="Cameras" reference="mservices">
//   //   <SelectInput optionText="name" />
//   // </ReferenceInput>,
// ];

export const FNozzleData = (props) => (
  <Datagrid {...props} size="medium">
    <TextField source="number" />
    <ReferenceField source="productId" reference="products">
      <TextField source="name" />
    </ReferenceField>
    <ReferenceField source="placeId" reference="fplaces">
      <TextField source="name" />
    </ReferenceField>
    <DeleteButton />
  </Datagrid>
);
export const FNozzleList = (props) => {
  const translate = useTranslate();
  return (
    <>
      <h2>{translate("resources.fnozzles.list")}</h2>
      <List {...props}>
        <FNozzleData />
      </List>
    </>
  );
};
