import * as React from "react";
import { SimpleForm, Create, useTranslate } from "react-admin";
import { TextInput, NumberInput } from "../../components/Inputs";
import { Grid, Typography } from "@mui/material";

export const ProductsCreate = (props) => {
  const translate = useTranslate();

  return (
    <>
      <Typography variant="h2">{translate("resources.products.create")}</Typography>
      <Create {...props} redirect="list">
        <SimpleForm>
          <Grid container spacing={2}>
            <Grid item md={6}>
              <TextInput source="name" />
              <TextInput source="units" />
              <TextInput source="productCode" />
              <TextInput source="unitCode" />
              <NumberInput source="vat" />
            </Grid>
          </Grid>
        </SimpleForm>
      </Create>
    </>
  );
};
