import * as React from "react";
import config from "../../config.json";
import {
  SimpleForm,
  Create,
  useTranslate,
  Form,
  useRedirect,
  useCreate,
} from "react-admin";

import { DateInput, SelectInput, TextInput } from "../../components/Inputs";
import { Grid, Card, Button, TextField } from "@mui/material";
import useTheme from "../../theme/index";

export const TenantsCreate = (props) => {
  const redirect = useRedirect();
  const theme = useTheme();
  const [create] = useCreate();
  const [orgData, setOrgData] = React.useState(null);
  const [errorData, setErrorData] = React.useState(false);
  const [validate, setValidate] = React.useState(false);

  const translate = useTranslate();

  const checkInn = (evt) => {
    let inputValue = evt.target.value;
    if (inputValue.length !== 9) {
      setValidate(true);
    } else {
      setValidate(false);
    }
  };

  const onSubmitStir = (data) => {
    console.log(
      data.target.parentNode.children[0].children[1].children[0].value
    );
    const stir =
      data.target.parentNode.children[0].children[1].children[0].value;
    fetch(`${config.API_URL}/organizationinfo/${stir}`)
      .then((res) => res.json())
      .then((data) => {
        setOrgData(data);
        if (data.status !== 404) {
          setOrgData(data);
        } else {
          setErrorData(true);
          setOrgData(null);
        }
      })
      .catch((err) => {
        setErrorData(true);
        console.error(err);
      });
  };
  const onSubmitClients = (data) => {
    console.log(data);
    const currentData = {
      name: data.name,
      address: data.address,
      executive: data.executive,
      phone: data.phone,
      registeredAt: data.registeredAt,
      status: data.status,
      identifier: data.stir,
      thsht: data.thsht,
      dbibt: data.dbibt,
      ifut: data.ifut,
      paymentTypeIds: data.paymentTypeIds,
    };
    create(
      "tenants",
      { data: currentData },
      {
        onSuccess: () => {
          redirect("/tenants");
        },
      }
    );
  };
  return (
    <>
      <h2>{translate("resources.tenants.create")}</h2>
      <Card {...props} style={{ padding: "1rem", marginTop: "1rem" }}>
        <Form>
          <Grid container spacing={2}>
            <Grid item md={6}>
              <TextField
                style={{ marginBottom: "15px" }}
                margin="normal"
                label={translate("static.stirPlaceholder")}
                fullWidth
                variant="outlined"
                id="stir"
                onChange={(evt) => checkInn(evt)}
                // ref={stirRef}
              />
              {errorData && (
                <h5
                  style={{
                    marginBottom: "15px",
                    color: theme.palette.error.main,
                  }}
                >
                  {translate("static.textErrInn")}
                </h5>
              )}
              {validate && (
                <h5
                  style={{
                    marginBottom: "15px",
                    color: theme.palette.error.main,
                  }}
                >
                  {translate("static.errorValidateText")}
                </h5>
              )}

              <Button
                onClick={onSubmitStir}
                type="submit"
                size="medium"
                variant="contained"
              >
                {translate("static.checkCompany")}
              </Button>
              <Button
                onClick={() => {
                  setOrgData(true);
                }}
                style={{ marginLeft: "15px" }}
                type="submit"
                size="medium"
                variant="outlined"
              >
                {translate("static.addNewCompany")}
              </Button>
            </Grid>
          </Grid>
        </Form>
      </Card>
      {orgData && (
        <Create {...props} redirect="list">
          <SimpleForm onSubmit={onSubmitClients}>
            <Grid container spacing={2}>
              <Grid item md={6}>
                <TextInput
                  source="name"
                  defaultValue={!orgData ? "" : orgData.name}
                  fullWidth
                />
              </Grid>

              <Grid item md={6}>
                <TextInput
                  source="address"
                  defaultValue={!orgData ? "" : orgData.address}
                  fullWidth
                />
              </Grid>
              <Grid item md={6}>
                <TextInput
                  source="stir"
                  defaultValue={!orgData ? "" : orgData.stir}
                  fullWidth
                />
              </Grid>
              <Grid item md={6}>
                <TextInput
                  source="executive"
                  defaultValue={!orgData ? "" : orgData.executive}
                  fullWidth
                />
              </Grid>
              <Grid item md={6}>
                <TextInput
                  source="phone"
                  defaultValue={!orgData ? "" : orgData.phone}
                  fullWidth
                />
              </Grid>
              <Grid item md={6}>
                <DateInput
                  source="registeredAt"
                  defaultValue={!orgData ? "" : orgData.registeredAt}
                  fullWidth
                />
              </Grid>

              {/* <Grid item md={4}>
                <TextInput
                  source="thsht"
                  defaultValue={!orgData ? "" : orgData.thsht?.code}
                  fullWidth
                />
              </Grid>

              <Grid item md={4}>
                <TextInput
                  source="dbibt"
                  defaultValue={!orgData ? "" : orgData.dbibt?.code}
                  fullWidth
                />
              </Grid>

              <Grid item md={4}>
                <TextInput
                  source="ifut"
                  defaultValue={!orgData ? "" : orgData.ifut?.code}
                  fullWidth
                />
              </Grid> */}
              <Grid item md={6}>
                <SelectInput
                  style={{ margin: "0px" }}
                  source="status"
                  defaultValue={!orgData ? "" : orgData.status}
                  fullWidth
                  // emptyText={!orgData ? "" : orgData.status}
                  // emptyValue={!orgData ? "" : orgData.status}
                  // disableValue={!orgData ? "" : orgData.status}
                  optionText="name"
                  optionValue="id"
                  choices={[
                    {
                      id: "active",
                      name: translate("resources.tenants.statusType.active"),
                    },
                    {
                      id: "undefined",
                      name: translate("resources.tenants.statusType.undefined"),
                    },
                    {
                      id: "ended",
                      name: translate("resources.tenants.statusType.ended"),
                    },
                  ]}
                />
              </Grid>
            </Grid>
          </SimpleForm>
        </Create>
      )}
    </>
  );
};
