import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  EditButton,
  DeleteButton,
  useTranslate,
  ArrayField,
  FunctionField,
  useGetList,
  useLocaleState,
} from "react-admin";

import {
  TextInput,
  // SelectInput,
  // ReferenceInput,
} from "../../components/Inputs";

const filters = [
  <TextInput source="q" label="Search" alwaysOn variant="outlined" />,
  // <ReferenceInput source="companyId" label="Company" reference="companies">
  //   <SelectInput optionText="name" variant="outlined" />
  // </ReferenceInput>,
  // <ReferenceInput source="areaId" label="Area" reference="areas">
  //   <SelectInput optionText="name" variant="outlined" />
  // </ReferenceInput>,
];

export const FStationList = (props) => {
  const translate = useTranslate();
  const { data } = useGetList("paymenttypes", {
    page: 1,
    perPage: 10,
  });
  const getLocale = useLocaleState();
  return (
    <>
      <h2>{translate("resources.fstations.list")}</h2>
      <List {...props} filters={filters}>
        <Datagrid size="medium" rowClick="show">
          <TextField source="name" />
          <ReferenceField source="areaId" reference="areas">
            <TextField source="name" />
          </ReferenceField>
          <TextField source="address" />
          <ArrayField source="paymentTypes">
            <FunctionField
              render={(record) => {
                return record.paymentTypes?.map((type) => {
                  return data?.map((item) => {
                    if (item.id === type) {
                      return item.locales.map((i) => {
                        if (i.localeKey === getLocale[0]) {
                          return (
                            <span
                              key={item.id}
                              style={{
                                backgroundColor: "#DFE3E8",
                                padding: "5px",
                                margin: "5px 5px 5px 0",
                                color: "black",
                                fontSize: "13px",
                                borderRadius: "1rem",
                              }}
                            >
                              {i.value}
                            </span>
                          );
                        }
                      });
                    }
                  });
                });
              }}
            />
          </ArrayField>
          <EditButton />
          <DeleteButton />
        </Datagrid>
      </List>
    </>
  );
};
