import * as React from "react";
// import config from "../../config.json";

import { Grid } from "@mui/material";
import {
  ArrayInput,
  SimpleForm,
  Edit,
  SimpleFormIterator,
  useTranslate,
} from "react-admin";
// import { useLocation } from "react-router-dom";
import {
  TextInput,
  ReferenceInput,
  SelectInput,
  NumberInput,
} from "../../components/Inputs";

export const FPlaceEdit = (props) => {
  const translate = useTranslate();
  // const location = useLocation();
  // const [data, setData] = React.useState();

  // React.useEffect(() => {
  //   fetch(`${config.API_URL}/fplaces/${location.pathname.slice(9)}`)
  //     .then((res) => res.json())
  //     .then((data) => setData(data));
  // }, []);

  // // const nozzles = data.nozzles;
  // // console.log(nozzles);

  return (
    <>
      <h2>{translate("resources.fplaces.edit")}</h2>
      <Edit {...props}>
        <SimpleForm>
          <Grid container spacing={2}>
            <Grid item md={6}>
              <TextInput disabled source="id" />
            </Grid>
            <Grid item md={6}>
              <NumberInput disabled={true} source="order" />
            </Grid>
            <Grid item md={6}>
              <ReferenceInput
                style={{ margin: 0 }}
                source="fStationId"
                reference="fstations"
              >
                <SelectInput
                  style={{ margin: 0 }}
                  translateChoice={false}
                  optionText="name"
                />
              </ReferenceInput>
            </Grid>
            <Grid item md={6}>
              <TextInput source="name" />
            </Grid>
            <ArrayInput source="nozzles">
              <SimpleFormIterator>
                <ReferenceInput source="productId" reference="products">
                  <SelectInput translateChoice={false} optionText="name" />
                </ReferenceInput>
                <NumberInput source="number" />
              </SimpleFormIterator>
            </ArrayInput>
          </Grid>
        </SimpleForm>
      </Edit>
    </>
  );
};
