import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  DeleteButton,
  useTranslate,
  ArrayField,
  FunctionField,
  useGetList,
  useLocaleState,
} from "react-admin";
import { TextInput } from "../../components/Inputs";

const filters = [
  <TextInput source="q" label="Search" alwaysOn />,
  // <ReferenceInput source="mServiceId" label="Cameras" reference="mservices">
  //   <SelectInput optionText="name" />
  // </ReferenceInput>,
];

export const ClientsList = (props) => {
  const translate = useTranslate();
  const { data } = useGetList("paymenttypes", {
    page: 1,
    perPage: 10,
  });
  const getLocale = useLocaleState();
  return (
    <>
      <h2>{translate("resources.clients.list")}</h2>
      <List {...props} filters={filters}>
        <Datagrid size="medium">
          <TextField source="name" />
          <TextField source="identifier" />
          <TextField source="address" />
          <TextField source="phone" />
          <TextField source="executive" />
          <ArrayField source="paymentTypeIds">
            <FunctionField
              render={(record) => {
                return record.paymentTypeIds.map((type) => {
                  return data?.map((item) => {
                    if (item.id === type) {
                      return item.locales.map((i) => {
                        if (i.localeKey === getLocale[0]) {
                          return (
                            <span
                              key={item.id}
                              style={{
                                backgroundColor: "#DFE3E8",
                                padding: "5px",
                                margin: "5px 5px 5px 0",
                                color: "black",
                                fontSize: "13px",
                                borderRadius: "1rem",
                              }}
                            >
                              {i.value}
                            </span>
                          );
                        }
                      });
                    }
                  });
                });
              }}
            />
          </ArrayField>
          <EditButton />
          <DeleteButton />
        </Datagrid>
      </List>
    </>
  );
};
