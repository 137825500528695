import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  DateField,
  ReferenceField,
  DeleteButton,
  useTranslate,
} from "react-admin";
import { TextInput } from "../../components/Inputs";

const filters = [
  <TextInput source="q" label="Search" alwaysOn />,
  // <ReferenceInput source="mServiceId" label="Cameras" reference="mservices">
  //   <SelectInput optionText="name" />
  // </ReferenceInput>,
];

export const ProductPricesList = (props) => {
  const translate = useTranslate();
  return (
    <>
      <h2>{translate("resources.productprices.list")}</h2>
      <List {...props} filters={filters}>
        <Datagrid size="medium">
          <DateField source="startDate" />
          <DateField source="endDate" />
          <TextField source="price" />
          <ReferenceField source="productId" reference="products">
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField source="fStationId" reference="fstations">
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField source="areaId" reference="areas">
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField source="clientId" reference="clients">
            <TextField source="name" />
          </ReferenceField>
          <EditButton />
          <DeleteButton />
        </Datagrid>
      </List>
    </>
  );
};
