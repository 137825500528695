import "./App.css";
import config from "./config.json";
import { i18nProvider } from "./i18n/i18nProvider";
import React, { useEffect, useState } from "react";
import { Admin, Resource } from "react-admin";
// import jsonServerProvider from "ra-data-json-server";
import apiProvider from "./services/apiService";
import useTheme from "./theme";
import MainLayout from "./layouts/main";
import DashboardApp from "./pages/DashboardApp";
// import Login from "./pages/Login";
import authProvider from "./sections/authentication/AuthProvider";

import { AreaList } from "./pages/area/area.list";
import { AreaEdit } from "./pages/area/area.edit";
import { AreaCreate } from "./pages/area/area.create";

import { AutoTypesList } from "./pages/autotypes/autotypes.list";
import { AutoTypesCreate } from "./pages/autotypes/autotypes.create";
import { AutoTypesEdit } from "./pages/autotypes/autotypes.edit";

import { AutoList } from "./pages/auto/auto.list";
import { AutoEdit } from "./pages/auto/auto.edit";
import { AutoCreate } from "./pages/auto/auto.create";

import { ClientsList } from "./pages/clients/clients.list";
import { ClientsCreate } from "./pages/clients/clients.create";
import { ClientsEdit } from "./pages/clients/clients.edit";

import { FCameraList } from "./pages/fcamera/fcamera.list";
import { FCameraCreate } from "./pages/fcamera/fcamera.create";
import { FCameraEdit } from "./pages/fcamera/fcamera.edit";

import { FPlaceList } from "./pages/fplace/fplace.list";
import { FPlaceCreate } from "./pages/fplace/fplace.create";
import { FPlaceEdit } from "./pages/fplace/fplace.edit";
import { FPlaceShow } from "./pages/fplace/fplace.show";

import { FStationList } from "./pages/fstation/fstation.list";
import { FStationCreate } from "./pages/fstation/fstation.create";
import { FStationEdit } from "./pages/fstation/fstation.edit";
import { FStationShow } from "./pages/fstation/fstation.show";

import { SuppliersList } from "./pages/suppliers/suppliers.list";
import { SuppliersCreate } from "./pages/suppliers/suppliers.create";
import { SuppliersEdit } from "./pages/suppliers/suppliers.edit";

import { FNozzleList } from "./pages/fnozzles/fnozzles.list";
import { FNozzleCreate } from "./pages/fnozzles/fnozzles.creat";

import { ProductsList } from "./pages/products/products.list";
import { ProductsCreate } from "./pages/products/products.create";
import { ProductsEdit } from "./pages/products/products.edit";

import { ProductPricesList } from "./pages/productprices/productprices.list";
import { ProductPricesCreate } from "./pages/productprices/productprices.create";
import { ProductPricesEdit } from "./pages/productprices/productprices.edit";

import { FSessionsList } from "./pages/fsessions/fsessions.list";

import { SuppliesCreate } from "./pages/supplies/supplies.create";
import { SuppliesList } from "./pages/supplies/supplies.list";
import { SuppliesEdit } from "./pages/supplies/supplies.edit";

import { TenantsList } from "./pages/tenants/tenants.list";
import { TenantsCreate } from "./pages/tenants/tenants.create";
import { TenantsEdit } from "./pages/tenants/tenants.edit";
import { FEventsList } from "./pages/fevents/fevents.list";
import { FSessionPaymentsList } from "./pages/fsessionpayments/fsessionpayments.list";
import { PaymentTypesList } from "./pages/paymenttypes/paymenttypes.list";
import { PaymentTypesEdit } from "./pages/paymenttypes/paymenttypes.edit";
import { PaymentTypesCreate } from "./pages/paymenttypes/paymenttypes.create";
import { FSessionStatesList } from "./pages/fsessionstates/fsessionstates.list";
import { FTotalsList } from "./pages/ftotals/ftotals.list";
import { FSessionsShow } from "./pages/fsessions/fsessions.show";
import { FSessionCapturesList } from "./pages/fsessioncapture/fsessioncapture.list";

const App = () => {
  const theme = useTheme();

  const [, setLocales] = useState([]);

  authProvider.checkAuth();

  useEffect(() => {
    fetchLocales();
  }, []);

  async function fetchLocales() {
    const response = await fetch(config.API_URL + "/locales");
    const json = await response.json();

    setLocales(json);
    apiProvider.locales = json;
    console.log(json);
  }
  return (
    <>
      <Admin
        theme={theme}
        layout={MainLayout}
        error={404}
        dataProvider={apiProvider}
        // authProvider={authProvider}
        i18nProvider={i18nProvider}
        dashboard={DashboardApp}
        // loginPage={Login}
        title="СТО Админ Панель"
      >
        {/* <Resource
          name="users"
          list={UserList}
          edit={UserEdit}
          create={UserCreate}
        /> */}
        <Resource name="locales" />
        <Resource name="roles" />
        <Resource name="syncs" />
        <Resource name="fevents" list={FEventsList} />
        <Resource name="fsessionpayments" list={FSessionPaymentsList} />
        <Resource name="fsessionstates" list={FSessionStatesList} />
        <Resource name="ftotals" list={FTotalsList} />
        <Resource name="fsessioncaptures" list={FSessionCapturesList} />
        <Resource
          name="paymentTypes"
          list={PaymentTypesList}
          edit={PaymentTypesEdit}
          create={PaymentTypesCreate}
        />
        <Resource
          name="areas"
          list={AreaList}
          edit={AreaEdit}
          create={AreaCreate}
        />
        <Resource
          name="autos"
          list={AutoList}
          edit={AutoEdit}
          create={AutoCreate}
        />
        <Resource
          name="autotypes"
          list={AutoTypesList}
          create={AutoTypesCreate}
          edit={AutoTypesEdit}
        />
        <Resource
          name="clients"
          list={ClientsList}
          create={ClientsCreate}
          edit={ClientsEdit}
        />
        <Resource
          name="fcameras"
          list={FCameraList}
          create={FCameraCreate}
          edit={FCameraEdit}
        />
        <Resource
          name="fplaces"
          list={FPlaceList}
          create={FPlaceCreate}
          edit={FPlaceEdit}
          show={FPlaceShow}
        />
        <Resource
          name="fstations"
          list={FStationList}
          create={FStationCreate}
          edit={FStationEdit}
          show={FStationShow}
        />

        <Resource name="fnozzles" list={FNozzleList} create={FNozzleCreate} />
        <Resource name="fsessions" list={FSessionsList} show={FSessionsShow} />
        <Resource
          name="suppliers"
          list={SuppliersList}
          create={SuppliersCreate}
          edit={SuppliersEdit}
        />
        <Resource
          name="supplies"
          list={SuppliesList}
          create={SuppliesCreate}
          edit={SuppliesEdit}
        />
        <Resource
          name="products"
          list={ProductsList}
          create={ProductsCreate}
          edit={ProductsEdit}
        />
        <Resource
          name="productprices"
          list={ProductPricesList}
          create={ProductPricesCreate}
          edit={ProductPricesEdit}
        />
        <Resource
          name="tenants"
          list={TenantsList}
          create={TenantsCreate}
          edit={TenantsEdit}
        />
      </Admin>
    </>
  );
};

export default App;
