import { Grid } from "@mui/material";
import * as React from "react";
import {
  Edit,
  SimpleForm,
  SimpleFormIterator,
  ArrayInput,
  useTranslate,
  BooleanInput,
} from "react-admin";
import { TextInput, NumberInput } from "../../components/Inputs";

export const PaymentTypesEdit = (props) => {
  const translate = useTranslate();
  return (
    <>
      <h2>{translate("resources.paymentTypes.edit")}</h2>
      <Edit {...props} redirect="list">
        <SimpleForm>
          <Grid container spacing={2}>
            <Grid item md={6}>
              <TextInput source="name" />
            </Grid>
            <Grid item md={6}>
              <NumberInput source="priority" />
            </Grid>
            <Grid item>
              <BooleanInput source="isPrintable" />
            </Grid>
          </Grid>
          <ArrayInput source="locales">
            <SimpleFormIterator disableRemove disableAdd>
              <TextInput source="localeKey" label="Locale" disabled />
              <TextInput source="value" label="Value" />
            </SimpleFormIterator>
          </ArrayInput>
        </SimpleForm>
      </Edit>
    </>
  );
};
