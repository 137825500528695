import * as React from "react";
// import { useLocation } from "react-router";
import { Grid } from "@mui/material";
import { SimpleForm, Create, useTranslate } from "react-admin";
import { useLocation } from "react-router-dom";
import {
  ReferenceInput,
  SelectInput,
  NumberInput,
} from "../../components/Inputs";

export const FNozzleCreate = (props) => {
  const translate = useTranslate();
  const location = useLocation();
  console.log(location);
  const fplaceId =
    location.state && location.state.record
      ? location.state.record.fplaceId
      : undefined;

  const redirect = fplaceId ? `/fplaces/${fplaceId}/show/fnozzles` : false;

  return (
    <>
      <h2>{translate("resources.fnozzles.create")}</h2>
      <Create {...props} redirect={redirect}>
        <SimpleForm margin={1}>
          <Grid container spacing={2}>
            <Grid item md={6}>
              <ReferenceInput
                style={{ margin: 0 }}
                source="productId"
                reference="products"
              >
                <SelectInput
                  style={{ margin: 0 }}
                  translateChoice={false}
                  optionText="name"
                />
              </ReferenceInput>
            </Grid>
            <Grid item md={6}>
              <NumberInput source="number" />
            </Grid>
            <Grid item md={6}>
              <ReferenceInput
                style={{ margin: 0 }}
                source="placeId"
                reference="fplaces"
              >
                <SelectInput
                  style={{ margin: 0 }}
                  translateChoice={false}
                  optionText="name"
                />
              </ReferenceInput>
            </Grid>
          </Grid>
        </SimpleForm>
      </Create>
    </>
  );
};
